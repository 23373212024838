import React from "react";
// import Image from "next/image";
// import Patron from "../../Assets/Images/about/Patrons/patron1.png";
const Patrons = (props) => {
  // const patron = require("../../../Assets/About/Patrons/" +
  //   props.image.image);
  const patron = props.image.image;

  console.log(patron);
  // const patron = props.image.image;

  return (
    <div className="bg-black rounded-xl md:rounded-3xl m-5 md:m-10">
      <div
        className="rounded-xl md:rounded-3xl border-2 border-solid border-[#987b6e] "
        style={{
          backgroundImage: `radial-gradient(#ffffff70 50%, #EF652280)`,
          backdropFilter: "blur(20px)",
          WebkitBackdropFilter: "blur(20px)",
        }}
      >
        <div className="m-auto p-1.5 md:p-3.5 rounded-lg md:rounded-2xl  w-20 md:w-52">
          <img className="" src={patron} alt="patron" />
        </div>
      </div>
    </div>
  );
};

export default Patrons;
