import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./Card.module.css";
import { useSelector } from "react-redux";
import { ImCross } from "react-icons/im";
import QRCode from "qrcode.react";

const Card = ({ event }) => {
  const user = useSelector((state) => state.user.user);
  const [check, setCheck] = useState([]);
  const [showModal, setShowModal] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  function clickFunction(e) {
    // // // console.log(e);
    var modal = document.querySelector(".main-modal");
    var buttons = document.querySelector(".modal-button");
    // // // console.log(modal.contains(e.target))
    var check = modal.contains(e.target) || buttons.contains(e.target);
    // // // console.log(check)
    if (!check) setShowModal(false);
  }
  useEffect(() => {
    if (user && user.events) {
      const check =
        user &&
        user.events.filter((data) => {
          return data === event.id;
        });
      setCheck(check);
      // setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      className={`max-w-[230px] lg:min-w-[50vh] md:min-w-[30vh] min-w-[30vh] sm:max-w-sm md:m-8 my-8 mx-2 justify-center shadow-lg h-fit drop-shadow-2xl duration-100 rounded-lg  transition-300 bg-[#ffffff10] backdrop-blur-lg`}
    >
      <Link to={`/events/${event.id}`}>
        <div
          className={`rounded-t-lg relative overflow-hidden  ${Styles.gwrCardBg} `}
        >
          <div className="flex object-contain justify-center lg:min-h-[50vh] md:min-h-[30vh] min-h-[30vh] sm:max-h-sm lg:max-h-[50vh] md:max-h-[30vh] max-h-[30vh]">
            <img
              className={
                "rounded-t-lg object-contain transition cursor-pointer z-0 "
              }
              src={event.image}
              alt={event.name}
            />
          </div>
        </div>
      </Link>

      <div className="rounded-b-lg ">
        {event && event.live ? (
          <div className="flex w-[100%]">
            {/* <Link to={`/events/${event.id}/register`} className="py-2 w-full rounded-bl border border-[#EF6522] text-[#EF6522] hover:text-white hover:bg-[#ef6622] transition ">
                  <div className="text-center">
                    Register Online
                  </div>
                </Link>
                <button className="py-2 w-full rounded-br border border-[#EF6522] text-[#EF6522] hover:text-white hover:bg-[#ef6622] transition " onClick={() => {
                  // // // console.log(`Entered ${index}`);
                  setShowModal(true);

                }}>
                  Register Offline
                </button> */}
            {event.reglink ? (
              <a
                href={event.reglink}
                target="_blank"
                className="py-2 w-full rounded-br border border-[#EF6522] text-[#EF6522] hover:text-white hover:bg-[#ef6622] transition rounded-lg mt-2 text-center "
                rel="noreferrer"
              >
                Register Now
              </a>
            ) : (
              <button className="py-2 px-4 w-[100%] rounded-b border border-red-500 text-red-500 hover:text-white hover:bg-red-500 transition rounded-lg mt-2">
                Closed
              </button>
            )}
          </div>
        ) : (
          <button className="py-2 px-4 w-[100%] rounded-b border border-red-500 text-red-500 hover:text-white hover:bg-red-500 transition rounded-lg mt-2">
            Closed
          </button>
        )}
        {/* {!check.length ? (
          <>
            {event && event.live ? (
              <div className="flex w-[100%]">
                <Link to={`/events/${event.id}/register`} className="py-2 w-full rounded-bl border border-[#EF6522] text-[#EF6522] hover:text-white hover:bg-[#ef6622] transition ">
                  <div className="text-center">
                    Register Online
                  </div>
                </Link>
                <button className="py-2 w-full rounded-br border border-[#EF6522] text-[#EF6522] hover:text-white hover:bg-[#ef6622] transition " onClick={() => {
                  // // // console.log(`Entered ${index}`);
                  setShowModal(true);

                }}>
                  Register Offline
                </button>
                {event.reglink ? (
                  <a
                    href={event.reglink}
                    target="_blank"
                    className="py-2 w-full rounded-br border border-[#EF6522] text-[#EF6522] hover:text-white hover:bg-[#ef6622] transition rounded-lg mt-2 text-center "
                  >
                    Register Now
                  </a>
                ) : (
                  <button className="py-2 px-4 w-[100%] rounded-b border border-red-500 text-red-500 hover:text-white hover:bg-red-500 transition rounded-lg mt-2">
                    Closed
                  </button>
                )}

                {showModal && (
                  <>
                    <div
                      className="justify-start items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50  transition"
                      // onClick={(e)=>{// // console.log('Click')}}
                      onClick={(e) => {
                        clickFunction(e);
                      }}
                    >
                      <div className="relative w-full h-full main-modal duration-500">
                        <div
                          className={` transition backdrop-blur-md rounded-lg relative flex flex-col w-full h-[100%] bg-[#ffffff40] bg-white outline-none focus:outline-none`}
                        >
                          <div
                            className="flex items-end justify-end p-3 z-50 text-2xl cursor-pointer "
                            onClick={() => {
                              setShowModal(false);
                            }}
                          >
                            <ImCross />
                          </div>

                          <div className="transition p-2 flex align-center items-center flex-col h-[100%]  text-white">
                            <QRCode
                              value={`eventId:${event.id}  , aaruushId: ${user.aaruushId} , emailid:${user.email}`}
                              size={200}
                            />
                            <p className="text-black text-center text-lg mt-3">
                              Get scanned this QR code at your nearest helpdesk
                              of Aaruush and get registered
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                )}
              </div>
            ) : (
              <button className="py-2 px-4 w-[100%] rounded-b border border-red-500 text-red-500 hover:text-white hover:bg-red-500 transition ">
                Closed
              </button>
            )}
          </>
        ) : (
          <div className="flex w-[100%]">
            <Link
              to={`/events/${event.id}/register`}
              className="py-2 w-full rounded-bl border  border-green-500  text-green-500 hover:text-white hover:bg-green-500 transition"
            >
              <div className="text-center">Registered</div>
            </Link>
            <Link
              to={`/events/${event.id}/certificate`}
              className="py-2 w-full rounded-br border  border-green-500  text-green-500 hover:text-white hover:bg-green-500 transition"
            >
              <div className="text-center">Download Certificate</div>
            </Link>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Card;
