import React, { useState } from "react";
import { Fade } from "react-reveal";
// import educates from "../../../Assets/Images/initiatives/bgimage/educates.svg";
import { usePalette } from "react-palette";
// import Modal from "../Modal"

const Card = ({ img, index, list }) => {
  // // // console.log(thumbnail)
  // const img = require("../../../Assets/Images/initiatives/bgimage/" +
  //   thumbnail);

  var { data } = usePalette(img);
  if (!data.vibrant) {
    data = {
      vibrant: "#ef652230",
    };
  }
  // const [showModal, setShowModal] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [show, setshow] = useState(false);
  // var current = list[index];

  return (
    <React.Fragment>
      <div
        className="rounded overflow-hidden md:m-3 m-2 mb-10 md:p-3 p-2 hover:cursor-pointer  transition ease-in-out backdrop-blur-2xl bg-gradient-to-br duration-300"
        style={{
          // backgroundImage: `linear-gradient(105.87deg,${data.darkVibrant},${data.vibrant},${data.darkVibrant})`,
          backgroundImage: `radial-gradient(#ffffff10 40%, ${data.vibrant})`,
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          backdropFilter: "brightness(50%)",
          WebkitBackdropFilter: "brightness(50%)",
        }}
      >
        <Fade cascade>
          <div className="hover:scale-105 transition md:m-5 m-2 mb-10">
            <img
              className=" md:w-[25vw] w-[40vw] justify-center "
              src={img}
              onClick={() => {
                setshow(true);
              }}
              alt="initiatives"
            />
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default Card;
