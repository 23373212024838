import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    event_category:[]
}

const eventSlice = createSlice({
  name: "eventCategory",
  initialState,
  reducers: {
      setEventCategory:(state,action)=>{
          state.event_category = action.payload
      }
  }
});

export const {setEventCategory} = eventSlice.actions

export default eventSlice.reducer