import React from "react";
import Profile from "./profile";
import Styles from "./profile.module.css";

export default function Teams({ teams }) {
  console.log(teams);
  const sortedteam = teams.sort(function (a, b) {
    // console.log(a.designation - b.designation);
    // return a.designation - b.designation;
    // return a.name - b.name;
    return a.timestamp - b.timestamp;
  });
  return (
    <React.Fragment>
      <div
        className={
          "relative justify-evenly flex mt-2 flex-row flex-wrap " +
          Styles.transformstyle
        }
      >
        {sortedteam.map((member, index) => {
          return (
            <div
              className={"cursor-pointer mx-7 relative " + Styles.teams}
              key={index}
            >
              <Profile members={member} key={index} />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
