import React from "react";
import Styles from "./Sponsor.module.css";
import Fade from "react-reveal/Fade";
import im from "../../Assets/Sponsors/abstract.svg";
function Sponsors({ props, index }) {
  // const data = {
  //     darkVibrant: "#ffffff80",
  //     lightVibrant: "#ffffff90",
  //     vibrant: "#ffffff90",
  // };
  const name = props.name.split("-").join(" ");
  const num = Math.floor(Math.random());
  return (
    <React.Fragment>
      <Fade>
        <div
          className={`${Styles.About} overflow-hidden hover:bg-[#ef652240] hover:shadow-lg shadow-white h-[140px] w-[100px] items-center rounded-lg px-5 md:px-12 py-1 basis-[1/4] md:basis-1/7 ml-[1vw] flex justify-center mt-5 backdrop-blur-lg`}
          id={props.id}
          key={index}
        >
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            <div className="text-center cursor-pointer">
              {/* <img
                className={`h-auto md:max-h-[${num}+50px] min-w-[200px] mx-auto my-auto absolute top-[${num}] left-[${
                  num - 0.8
                }] -z-20`}
                src={im}
                alt={props.name}
              /> */}
              <img
                className="h-auto md:max-h-[45px] min-w-[30px] mx-auto my-auto"
                src={props.image}
                alt={props.name}
              />
              <h3 className="mt-3 capitalize text-sm lg:text-md">{name}</h3>
              {/* <p className="mt-3 text-aaruush capitalize text-sm">
                {props.type}
              </p> */}
            </div>
          </a>
        </div>
      </Fade>
    </React.Fragment>
  );
}

export default Sponsors;
