// import Image from "next/image";
import React from "react";
import peopleLogo from "../../Assets/About/peopleLogo.png";

export default function Section2_3(props) {
  return (
    // <div className='pt-60 m-auto'>
    <div className="flex flex-col md:flex-row p-1 pb-10  max-w-[30vw] md:max-w-[50vw]">
      <div className="flex flex-row pl-8">
        <div className="bg-black bg-opacity-30 rounded-full w-10 h-10 sm:w-20 sm:h-20 text-center text-[16px] sm:text-[26px] font-SFDistantGalaxy pt-[10px] sm:pt-[20px] sm:pl-[2px] z-[1] relative left-0 top-2 border-[#9F9F9F] border-[1px]">
          {props.turnout}
        </div>
        <div className="z-[0] relative -left-12 sm:-left-10 top:2 sm:top-0 w-10 sm:w-20">
          <img className="" src={peopleLogo} alt="Pic Ring" />
        </div>
      </div>
      <p className="font-Montserrat text-[10px] sm:text-[24px] text-center pt-5 flex">
        {props.edition}
      </p>
    </div>
    // <div className="flex flex-col md:flex-row p-1 pb-10  max-w-[30vw] md:max-w-[50vw]">
    //   <div className="flex flex-row pl-8">
    //     <div className="bg-black bg-opacity-30 rounded-full w-10 h-10 sm:w-20 sm:h-20 text-center text-[16px] sm:text-[32px] font-SFDistantGalaxy pt-[10px] sm:pt-[15px] z-[1] relative left-0 top-2 border-[#9F9F9F] border-[1px]">
    //       75
    //     </div>
    //     <div className="z-[0] relative -left-12 sm:-left-10 top:2 sm:top-0 w-10 sm:w-20">
    //       <Image className="" src={peopleLogo} alt="Pic Ring" />
    //     </div>
    //   </div>
    //   <p className="font-Montserrat text-[10px] sm:text-[24px] text-center pt-5 flex">
    //     Lorem ipsum dolor sit amet,
    //   </p>
    // </div>
  );
}
