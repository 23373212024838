import React from "react";
import Style from "./404.module.css";
import bgimg from "./404.png"
import { Link } from "react-router-dom"
// import Head from "next/head"
// import Image from "next/image"
export default function NotFound() {
    return (
        <React.Fragment>

            <div className={"main min-h-[100vh] flex flex-column-reverse flex-wrap md:px-20 pt-[15vh] px-10 pb-10 bg-bgcolor"}>
                <div className={" md:basis-1/3"}>
                    <div className={Style.heading + " md:mt-[3vh]"}>
                        Something Went Wrong!
                    </div>
                    <div className={Style.subhead}>
                        We can’t seem to find the page you are looking for!
                    </div>
                    <div className="mt-20">
                        <Link to="/"><button className={"px-7 py-2 rounded-lg bg-[#EF6522] text-white hover:bg-[#ef6622c4] transition "}>Back to Home</button></Link>
                    </div>
                </div>
                {/* <div className="relative md:basis-2/3 md:order-last order-first"> */}
                <div className="relative md:w-[40vw] w-[40vw] h-auto mx-auto mt-10 md:order-last order-first">
                    {/* <Image src={bgimg} layout="fill" objectFit="contain" className="" /> */}
                    <img src={bgimg} className="md:w-[40vw] w-[90vw] mx-auto mt-10" alt="not found" />
                </div>
            </div>
        </React.Fragment>
    );
}