import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { BrowserRouter } from "react-router-dom";
// import vid from "./BWA.mp4";

if (process.env.NODE_ENV === "development") {
  config.oauth.redirectSignIn = "http://localhost:3001/";
  config.oauth.redirectSignOut = "http://localhost:3001/";
} else {
  config.oauth.redirectSignIn = "https://22nd.aaruush.org/";
  config.oauth.redirectSignOut = "https://22nd.aaruush.org/";
}
Amplify.configure({ ...config, ssr: true });

const root = ReactDOM.createRoot(document.getElementById("root"));
// const useGenerateState = () => {
//   const [play, setPlay] = useState(true);
//   return {
//     state: {
//       play,
//     },
//     setters: {
//       setPlay,
//     },
//   };
// };
// const { state, setters } = useGenerateState();
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <div className="fixed m-0 p-0 w-full bg-bgcolor z-[1000]">
        <Navbar />
      </div>
      <App />
      <Footer />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
