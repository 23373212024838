import React, { useState } from "react";
import Styles from "./footer.module.css";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import envision from "../../Assets/Footer/envision.svg";
import { Link } from "react-router-dom";
import API from "../../Services/axios";
import aaruush_logo from "../../Assets/Navbar/aaruush-header.png";

export default function Footer() {
  const [email, setEmail] = useState("");
  const subscribe_to_newsletter = async (e) => {
    e.preventDefault();
    const res = (await API.post("/newsletter", { email })).data.message;
    alert(res);
    setEmail("");
  };
  return (
    <React.Fragment>
      <div
        className={
          "flex flex-wrap flex-row pt-[5vh] shadow-inner  shadow-[#EF652280] min-h-[22vh] text-white pb-7 text-center lg:text-left bg-bgcolor " +
          Styles.footer
        }
      >
        <div
          className={
            Styles.aaruush +
            " lg:basis-1/6 basis-full flex justify-center align-center self-center flex-column flex-wrap mx-2"
          }
        >
          <div className={"basis-full"}>
            {/* A<span className={Styles.orange}>A</span>RUUSH 20 */}
            <img src={aaruush_logo} alt="logo" className="w-96"></img>

            {/* <span className={Styles.orange}>22</span> */}
          </div>
          <div
            className={
              "mt-5 flex flex-row flex-wrap lg:text-xl md:text-4xl text-2xl justify-between basis-[71%] transition "
            }
          >
            <a
              className={Styles.orangelink}
              href="https://www.linkedin.com/company/aaruush-srm-ist/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              className={Styles.orangelink}
              href="https://www.instagram.com/aaruush_srm/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              className={Styles.orangelink}
              href="https://www.facebook.com/aaruush.srm"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              className={Styles.orangelink}
              href="https://www.youtube.com/c/AaruushSRMIST"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube />
            </a>
            <a
              className={Styles.orangelink}
              href="https://twitter.com/aaruushsrmist"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              className={Styles.orangelink}
              href="https://aaruushblog.wordpress.com/"
              target="_blank"
              rel="noreferrer"
            >
              <FaWordpress />
            </a>
          </div>
          <a
            href="https://envision.aaruush.org"
            target="_blank"
            rel="noreferrer"
            className="lg:block hidden"
          >
            <img
              src={envision}
              className="mt-10 w-[80%] mx-auto"
              alt="team envision"
            />
          </a>
        </div>
        <div className="basis-full lg:basis-1/6 mt-5 xl:ml-2 2xl:ml-5">
          <span className={Styles.footer_head}>AARUUSH</span>
          <ul className={Styles.child}>
            <Link to="/about">
              <li
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                About us
              </li>
            </Link>
            {/* <Link to="/">
              <li>Flagships</li>
            </Link>
            <Link to="/">
              <li>Highlights</li>
            </Link> */}
            <Link to="/events">
              <li
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Events
              </li>
            </Link>
            <a href="https://tsummit.aaruush.org">
              <li>T-summit</li>
            </a>
            {/* <Link to="/">
              <li>Orchestrate</li>
            </Link> */}
            {/* <Link to="/">
              <li>Blogs</li>
            </Link> */}
            <a href="https://cap.aaruush.org">
              <li>Campus Ambassador</li>
            </a>
            <Link to="/team">
              <li
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Team
              </li>
            </Link>
          </ul>
        </div>
        <div className="lg:basis-1/5 basis-full mt-5 mx-2">
          <span className={Styles.footer_head}>Contact Us</span>
          <ul className={Styles.childlist + " "}>
            <li>
              <a href="mailto:csiorg@aaruush.net">csiorg@aaruush.net</a>
            </li>
            <li>Pranav Khandelwal: +91 93995 83599</li>
            <li>Ritika Agarwal: +91 92057 34127</li>
            <li>Siddhant Priyadarshi +91 62328 83113</li>
          </ul>
        </div>
        <div className="lg:basis-1/6 xl:basis-1/5 mt-5 basis-full">
          <span className={Styles.footer_head}>Join Us</span>
          <ul className={Styles.childlist}>
            <a href="https://cap.aaruush.org">
              <li>Be a Campus Ambassador</li>
            </a>
            <a href="mailto:sponsorship@aaruush.net">
              <li>Sponsor us </li>
            </a>
            <a href="mailto:initiatives@aaruush.net">
              <li>Partner for an Initiative</li>
            </a>
            <a href="mailto:workshops@aaruush.net">
              <li>Partner for a Workshop</li>
            </a>
          </ul>
        </div>
        <div className="lg:basis-1/5 basis-full mt-5 mx-auto lg:w-[25%]">
          <span className="pb-10">Stay up to date on the latest events</span>
          <div className=" mx-auto flex justify-center lg:flex-0 lg-justify-none lg:-ml-4 mt-2">
            <form
              className="relative inline"
              onSubmit={subscribe_to_newsletter}
            >
              {/* <div className="relative flex w-72 flex-wrap items-stretch mb-3 mt-5 ">
                <input
                  type="email"
                  placeholder="Email"
                  className="px-3 py-3 relative bg-transparent rounded text-sm border shadow focus:outline-none ring-[#EF6522] focus:ring w-70 pr-10"
                />
                <button className="z-10 h-[2.4rem] leading-snug font-normal text-center absolute bg-transparent text-xl items-center justify-center mr-[4rem] w-8 -right-1 mt-1 pr-10 py-1 text-white rounded bg-[#EF6522] hover:bg-[#ca551a]">
                  <TiTick style={{ marginLeft: "0.7rem" }} />
                </button>
              </div> */}
              <label className="relative block">
                <input
                  className="placeholder:text-slate-400 block bg-transparent w-full border border-slate-300 rounded-md py-2 pl-3 pr-9 shadow-sm focus:outline-none focus:border-[#EF6522] focus:ring-[#EF6522] focus:ring-1 transition sm:text-sm"
                  placeholder="Email"
                  type="email"
                  name="subscribe"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="absolute inset-y-0 right-1 flex items-center pl-2">
                  <button type="submit" className="mr-3">
                    <TiTick
                      style={{
                        marginLeft: "0.7rem",
                        color: "white",
                        backgroundColor: "#EF6522",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </button>
                  {/* <button className="z-10 h-[2.4rem] leading-snug font-normal text-center absolute bg-transparent text-xl items-center justify-center w-8  pr-10 py-1 text-white rounded bg-[#EF6522] hover:bg-[#ca551a]">
                    <TiTick style={{ marginLeft: "0.7rem" }} />
                  </button> */}
                </span>
              </label>
            </form>
          </div>
          <a
            href="https://envision.aaruush.org"
            target="_blank"
            rel="noreferrer"
            className="lg:hidden block"
          >
            <img
              src={envision}
              className="mt-10 w-[60%] mx-auto"
              alt="team envision"
            />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
