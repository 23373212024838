import React from "react";
import Segment from "./Segment";

function Sponsors({ sponsors, category }) {
  // // console.log(sponsors, category);
  // // // console.log(props)
  return (
    <div className="text-white mt-10">
      {category?.sort((a,b)=>{
        return  b.timestamp - a.timestamp
      }).map((sponsor, idx) => {
        // // console.log(sponsor);
        return (
          <Segment
            props={sponsors}
            categoryid={sponsor.id}
            key={idx}
            categoryData={category}
          />
        );
      })}
    </div>
  );
}

export default Sponsors;
