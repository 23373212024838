import React from "react";
import Styles from "./Sponsor.module.css";
import Fade from "react-reveal/Fade";
import ImageSegment from "./ImageSegment";
import axios from "axios";

function Sponsors({ props, categoryid, categoryData }) {
  // const imgsrc = require("../Assets/Images/sponsors/" + props.image);
  // // // console.log(categoryData)
  // // console.log(props);
  const container = {
    whileInView: {
      opacity: 1,
      transition: {
        type: "spring",
        delayChildren: 1,
        duration: 2,
        bounce: 0.5,
        delay: 2,
      },
    },
    show: {
      opacity: 1,
      transition: {
        type: "spring",
        delayChildren: 1,
        duration: 2,
        bounce: 0.5,
        delay: 2,
      },
    },
  };
  const spons = categoryData.filter((data) => {
    if (data.id === categoryid) return data;
  });
  // // console.log(categoryData);
  // // console.log(category);
  // // console.log(spons);
  var thissponsor = spons[0];

  // // // console.log(thissponsor.map(s=>// // console.log(s.name)))
  // // // console.log(categoryData[category[0]]);
  // var sponsor = categoryData.category;
  // // // console.log(myspons);
  // // // console.log(categoryData)
  // console.log(props);
  return (
    <React.Fragment>
      {thissponsor && (
        <Fade bottom cascade>
          <div className="md:pb-20 pb-20">
            <div className="md:min-w-full flex justify-center ">
              <div
                className={
                  Styles.segment +
                  " md:w-[25vw] md:h-[50vh] md:mt-[10vh] md:ml-[1vw] flex justify-center flex-column md:flex-nowrap flex-wrap "
                }
              >
                <img
                  src={thissponsor.image}
                  className={
                    "md:absolute select-none md:w-[24vw] w-[200px] md:mt-4 justify-center md:ml-0 ml-5 opacity-[0.30]"
                  }
                />
                <div
                  className={
                    Styles.sponsorshead +
                    ` md:translate-y-[-12vh] md:translate-x-[0] md:md:translate-y-[-2vh] translate-y-[-18vh] md:w-[0vw] w-[10px] flex justify-center basis-full`
                  }
                >
                  {thissponsor.name.split("-").join(" ")}
                </div>
              </div>
            </div>
            <div className="md:w-100 md:ml-1 md:mt-[15vh] flex flex-column flex-wrap justify-center">
              {/* {props.members.map((member, index) => {
                return <ImageSegment props={member} key={index} />;
              })} */}

              {/* {Object.keys(props).map((member, index) => {
                return <ImageSegment props={props[member]} key={index} />;
              })} */}
              {props?.map((sponsor, idx) => {
                if (sponsor.category == categoryid) {
                  return <ImageSegment props={sponsor} key={idx} />;
                }
              })}
            </div>
          </div>
        </Fade>
      )}
    </React.Fragment>
  );
}

export default Sponsors;
