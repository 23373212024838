/* eslint-disable no-unused-vars */
import React, { Suspense, useState, useEffect } from "react";
import { Routes, Route, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
// import Spinner from "./components/Loading/Loading";
import Home from "./pages/home";
import About from "./pages/about";
import NotFound from "./components/404/404";
import Contact from "./components/Contact/Contact";

// import Gallery from "./pages/gallery";
import Gwr from "./pages/gwr";
import Initiatives from "./pages/initiatives";
import LiveEvents from "./pages/liveevents";
import Sponsors from "./pages/sponsors";
import Team from "./pages/team";
import Timeline from "./pages/timeline";
import EventDetail from "./pages/eventDetail";
import Verify from "./pages/verify";
// import EventRegister from "./pages/eventRegister";
// import Profile from "./pages/profile"
// import ScanQR from "./pages/scanQR"

import Lottie from "react-lottie";
import loadingaaruush from "./Assets/aaruushloading.json";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Events = React.lazy(() => import("./pages/events"));
const Gallery = React.lazy(() => import("./pages/gallery"));
function App() {
  const [userdata, setUserdata] = useState();
  const Lottiedefaultoptions = {
    loop: true,
    autoplay: true,
    animationData: loadingaaruush,
    rendererSettings: {
      preserveAspectRatio: "xMindYMid slice",
    },
  };
  useEffect(() => {
    checkUser();
    async function checkUser() {
      const user = Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data.attributes) {
            setUserdata(data.attributes);
          }
        })
        .catch((err) => {
          Cookies.remove("accessToken");
        });
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        <Route
          path="/gallery"
          element={
            <Suspense
              fallback={
                <div className="h-screen w-screen flex relative justify-center items-center bg-bgcolor">
                  <Lottie options={Lottiedefaultoptions} />
                </div>
              }
            >
              <Gallery />
            </Suspense>
          }
        />
        <Route path="/gwr" element={<Gwr />} />
        <Route path="/initiatives" element={<Initiatives />} />
        {/* <Route path="/liveevents" element={<LiveEvents />} /> */}
        <Route path="/sponsors" element={<Sponsors />} />
        <Route path="/team" element={<Team />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route
          path="/events"
          element={
            <Suspense
              fallback={
                <div className="h-screen w-screen flex relative justify-center items-center bg-bgcolor">
                  <Lottie options={Lottiedefaultoptions} />
                </div>
              }
            >
              <Events />
            </Suspense>
          }
        />
        {/* <Route path="/events/:eventId" element={<EventDetail />} /> */}
        <Route path="/verify/:id" element={<Verify />} />
        {/* <Route path="/events/:eventId/register" element={<EventRegister />} /> */}
        {/* <Route path="/events/:eventId/certificate" element={<EventRegister />} /> */}
        {/* <Route path="/verifycertificate/:eventId/:emailid" element={<EventRegister />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
