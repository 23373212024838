import style from "./Sponsor.module.css";
export default function Card({ name, email, phone, designation }) {
  return (
    <div className="overflow-hidden md:w-[25vw]">
      <div
        className={
          "flex flex-col gap-2 bg-[#ffffff10] backdrop-blur-md mx-1 my-1 " +
          style.About
        }
      >
        <div className="text-white text-3xl font-bold">{name}</div>
        <a
          href={"mailto:" + email}
          target="_blank"
          className="hover:text-[#ef6522] transition"
        >
          <div className="text-white text-lg">{email}</div>
        </a>
        <a
          href={"tel:" + phone}
          target="_blank"
          className="hover:text-[#ef6522] transition"
        >
          <div className="text-white text-lg">{phone}</div>
        </a>
        <div className="text-white text-lg">{designation}</div>
      </div>
    </div>
  );
}
