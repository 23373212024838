import React from "react";
import Styles from "../components/teampage/profile.module.css";
import Teams from "../components/teampage/teams";
import API from "../Services/axios";
import loadingaaruush from "../Assets/aaruushloading.json";
import Lottie from "react-lottie";
import data from "../data/data.json";
import { useEffect, useState } from "react";
function groupBy(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export default function Teampage() {
  const Lottiedefaultoptions = {
    loop: true,
    autoplay: true,
    animationData: loadingaaruush,
    rendererSettings: {
      preserveAspectRatio: "xMindYMid slice",
    },
  };
  const [teams, setTeams] = useState();
  useEffect(() => {
    async function getTeams() {
      // const teams = (await API.get(`/static/team`)).data;
      const teams = data["teams"];
      console.log(teams);
      setTeams(teams);
    }
    getTeams();
  }, []);

  const teamdata = teams && groupBy(teams, "category");
  console.log(teamdata);
  return (
    <React.Fragment>
      <div className={"bg-bgcolor min-h-[100vh] scroll-smooth pb-10 pt-20"}>
        <div className={Styles.mainhead}>
          A<span className={Styles.orange}>A</span>RUUSH 20
          <span className={Styles.orange}>22</span>
        </div>
        <div className={Styles.legacy}>CORE TEAM</div>
        <div className={Styles.heading}>CORE TEAM</div>
        <div className={Styles.headingdesc}>
          The strength of the team is each individual member. The strength of
          each member is the team
        </div>
        {teams ? (
          <div id="main" className="duration-200 relative">
            {teamdata["Administrators"] && (
              <div className="">
                <div className={Styles.subhead}>Administrators</div>
                <Teams teams={teamdata["Administrators"]} />
              </div>
            )}

            {teamdata["Team Envision"] && (
              <div className="">
                <div className={Styles.subhead}>Team Envision</div>
                <Teams teams={teamdata["Team Envision"]} />
              </div>
            )}

            {teamdata["Domains"] && (
              <div className="">
                <div className={Styles.subhead}>Domains</div>
                <Teams teams={teamdata["Domains"]} />
              </div>
            )}

            {teamdata["Committees"] && (
              <div className="">
                <div className={Styles.subhead}>Committees</div>
                <Teams teams={teamdata["Committees"]} />
              </div>
            )}
          </div>
        ) : (
          <div className="h-screen w-screen flex relative justify-center items-center bg-bgcolor">
            <Lottie options={Lottiedefaultoptions} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
