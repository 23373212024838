import React from "react";
import group5 from "../Assets/gwr/group5.png";
import group3 from "../Assets/gwr/group3.png";
import group3_mob from "../Assets/gwr/group3_mobile.png";
import group3_inv from "../Assets/gwr/group3_inv.png";
// import top_bg from '../components/Assets/Images/gwr/top_bg.png'

import { Fragment } from "react";

export default function gwr() {
  return (
    <div className="w-screen h-max bg-bgcolor">
      {/* <Photo5/> */}
      <Fragment>
        <div className="flex flex-col md:flex-row justify-between py-0 sm:py-20">
          <div className="bg-[url(./Assets/gwr/top_bg_mob.png)] md:bg-[url(./Assets/gwr/top_bg.png)] bg-contain bg-no-repeat mt-5 mx-5 md:mx-0 md:my-40 text-white p-10 md:p-20 md:w-3/4">
            <div className="font-SFDistantGalaxy text-[24px] sm:text-[54px] leading-6 sm:leading-10 md:px-10 text-center md:text-left pt-8 md:pt-0">
              Aaruush
              <span className=" ml-5 mr-5 mb-2 before:block before:absolute before:-inset-1 before:translate-y-5 before:ml-3 before:h-[15px] md:before:h-[30px] before:bg-[#EF6522] relative inline-block">
                <span className="relative text-white">World</span>
              </span>
              Records
            </div>
            {/* <div className="font-Montserrat text-[18px] md:text-[24px] pt-10 md:px-10 text-center md:text-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </div> */}
          </div>
          {/* <div className='text-6xl' style={{
                        backgroundColor: " #111111",
                        color: "transparent",
                        textShadow: " 0px 2px 3px #EF6522",
                        WebkitBackgroundClip: "text",
                        MozBackgroundClip: "text",
                        backgroundClip: "text"
                    }}>
                        . . . . . . . . . . .
                    </div> */}
          <div className="mx-auto md:w-[50vw]">
            <img
              className=" m-auto md:m-0 w-5/6 md:w-[50vw]"
              src={group5}
              alt=""
            />
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="my-20 md:my-52">
          <div className="flex flex-col md:flex-row flex-wrap justify-between">
            <div className="">
              <img className="w-3/4 hidden md:block" src={group3} alt="" />
            </div>
            <div className="my-auto bg-[url(./Assets/gwr/secondary_bg_mob.png)] md:bg-[url(./Assets/gwr/secondary_bg.png)] bg-contain bg-no-repeat bg-top md:bg-right	 h-80">
              <div className="font-SFDistantGalaxy text-[24px] sm:text-[54px] text-white text-center md:text-right py-12 px-20">
                Aaruush'
                <span className="text-[#EF6522]">19</span>
                {/* <span className="">...</span> */}
              </div>

              <div className="font-Montserrat font-black mb-4 -mt-8 text-[18px] md:text-[24px] text-white text-center md:text-right md:w-[55vw] px-10 md:px-20">
                Largest Software Lesson
              </div>
              <div className="font-Montserrat text-[18px] md:text-[24px] text-white text-center md:text-right md:w-[55vw] px-10 md:px-20">
                Team Aaruush attempted for a Guinness World Record for the
                Largest Software Lesson in Adobe Lightroom on the 7th of
                September, 2019. The previously set record saw a participation
                of 775 people and SRM handsomely broke the record with 905
                people attending the lesson. The speaker for the session was
                professional photographer Samuel Christudas Albert, founder of
                the Mirage Film Institute in Chennai.
              </div>
            </div>
            <div className=" md:m-0 w-screen">
              <img
                className="w-full block md:hidden my-20 md:my-40 md:m-0"
                src={group3_mob}
                alt=""
              />
            </div>
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="my-52 hidden md:block">
          <div className="flex flex-row flex-wrap justify-between">
            <div className="my-auto bg-[url(./Assets/gwr/secondary_bg_inv.png)] bg-contain bg-no-repeat bg-left	 h-80">
              <div className="font-SFDistantGalaxy text-[24px] sm:text-[54px] text-white text-lext py-12 px-20">
                Aaruush'
                <span className="text-[#EF6522]">18</span>
              </div>
              <div className="font-Montserrat font-black mb-4 -mt-8 text-[18px] md:text-[24px] text-white text-center md:text-left md:w-[55vw] px-10 md:px-20">
                Largest Human Image of Infinity Symbol
              </div>
              <div className="font-Montserrat text-[18px] md:text-[24px] text-white text-left w-[55vw] px-10 md:px-20">
                The attempt was made on 22nd August 2018, which had led to a
                turnabout of 545 students from the institution. With the theme
                of “Aaruush Towards Infinity”, it displayed the limitless
                boundaries of the human mind, the event displayed the orchestral
                unity of the institution and its students.
              </div>
            </div>
            <div className="flex justify-end items-end">
              <img className="w-3/4 justify-end" src={group3_inv} alt="" />
            </div>
          </div>
        </div>

        <div className="my-20 md:my-0 block md:hidden">
          <div className="flex flex-col md:flex-row flex-wrap justify-between">
            {/* <div className=''>
                            <img className="w-3/4 hidden md:block" src={group3} alt="" />
                        </div> */}
            <div className="my-auto bg-[url(./Assets/gwr/secondary_bg_mob.png)] md:bg-[url(./Assets/gwr/secondary_bg.png)] bg-contain bg-no-repeat bg-top md:bg-right	 h-80">
              <div className="font-SFDistantGalaxy text-[24px] sm:text-[54px] text-white text-center md:text-right py-12 px-20">
                Aaruush'
                <span className="text-[#EF6522]">17</span>
                {/* <span className="">...</span> */}
              </div>
              <div className="font-Montserrat font-black mb-4 -mt-8 text-[18px] md:text-[24px] text-white text-center md:text-left md:w-[55vw] px-10 md:px-20">
                Largest Human Image of Infinity Symbol
              </div>
              <div className="font-Montserrat text-[18px] md:text-[24px] text-white text-left w-[55vw] px-10 md:px-20">
                The attempt was made on 22nd August 2018, which had led to a
                turnabout of 545 students from the institution. With the theme
                of “Aaruush Towards Infinity”, it displayed the limitless
                boundaries of the human mind, the event displayed the orchestral
                unity of the institution and its students.
              </div>
            </div>
            <div className=" md:mt-0 w-screen ">
              <img
                className="w-full block md:hidden my-20 md:m-0"
                src={group3_mob}
                alt=""
              />
            </div>
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="md:py-52 py-20">
          <div className="flex flex-col md:flex-row flex-wrap justify-between">
            <div className="">
              <img className="w-3/4 hidden md:block" src={group3} alt="" />
            </div>
            <div className="my-auto bg-[url(./Assets/gwr/secondary_bg_mob.png)] md:bg-[url(./Assets/gwr/secondary_bg.png)] bg-contain bg-no-repeat bg-top md:bg-right	 h-80">
              <div className="font-SFDistantGalaxy text-[24px] sm:text-[54px] text-white text-center md:text-right py-12 px-20">
                Aaruush'
                <span className="text-[#EF6522]">17</span>
                {/* <span className="">...</span> */}
              </div>
              <div className="font-Montserrat font-black mb-4 -mt-8 text-[18px] md:text-[24px] text-white text-center md:text-right md:w-[55vw] px-10 md:px-20">
                SRM Eco Run
              </div>
              <div className="font-Montserrat text-[18px] md:text-[24px] text-white text-center md:text-right md:w-[55vw] px-10 md:px-20">
                Grasping the need for living in a pristine environment at the
                grass root level, Aaruush organized an Eco Run on the morning of
                the 71st Independence Day of India to make people aware about
                the importance of environment conservation. With the motto of
                'Go Clean, Go Green', the 5 Km marathon saw a participation of
                1181 participants.
              </div>
            </div>
            <div className=" md:m-0 w-screen">
              <img
                className="w-full block md:hidden my-20 md:m-0"
                src={group3_mob}
                alt=""
              />
            </div>
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="mt-20 md:mt-52 pb-16">
          <div className="flex flex-col md:flex-row flex-wrap justify-between">
            <div className="">
              <img className="w-3/4 hidden md:block" src={group3} alt="" />
            </div>
            <div className="my-auto bg-[url(./Assets/gwr/secondary_bg_mob.png)] md:bg-[url(./Assets/gwr/secondary_bg.png)] bg-contain bg-no-repeat bg-top md:bg-right	 h-80">
              <div className="font-SFDistantGalaxy text-[24px] sm:text-[54px] text-white text-center md:text-right py-12 px-20">
                Aaruush'
                <span className="text-[#EF6522]">16</span>
                {/* <span className="">...</span> */}
              </div>
              <div className="font-Montserrat font-black mb-4 -mt-8 text-[18px] md:text-[24px] text-white text-center md:text-right md:w-[55vw] px-10 md:px-20">
                Longest Human Selfie Chain
              </div>
              <div className="font-Montserrat text-[18px] md:text-[24px] text-white text-center md:text-right md:w-[55vw] px-10 md:px-20">
                To leave a mark in the trending word of selfies, Team Aaruush
                made an attempt on 13th September 2016 for the Longest Human
                Selfie Chain in front of the Dr. T. P. Ganesan Auditorium, which
                attracted a huge footfall of SRM students.
              </div>
            </div>
            <div className=" md:m-0 w-screen">
              <img
                className="w-full block md:hidden my-20 md:my-40 md:m-0"
                src={group3_mob}
                alt=""
              />
            </div>
          </div>
        </div>
      </Fragment>{" "}
    </div>
  );
}
