import React from "react";
import Fade from "react-reveal/Fade";
import { usePalette } from "react-palette";

function Sponsors({ props }) {
  const propimg = props.value;
  const { data } = usePalette(propimg);
  console.log(props);
  return (
    <React.Fragment>
      <Fade>
        <div
          className={`md:h-[70px] h-[80px] w-[140px] items-center rounded-lg backdrop-blur-2xl bg-gradient-to-br px-5 md:px-8 py-1 basis-1/5 mr-[1vw] flex justify-center mt-5 hover:cursor-pointer transition shadow-md duration-300`}
          style={{
            backgroundImage: `linear-gradient(105.87deg,${data.darkVibrant} 10%, ${data.lightVibrant} 60%,${data.vibrant} )`,
            backdropFilter: "brightness(50%)",
            WebkitBackdropFilter: "brightness(50%)",
          }}
          id={props.id}
        >
          <img
            className="h-auto md:max-h-[90%] min-w-[70px]"
            src={propimg}
            alt={props.label}
          />
        </div>
      </Fade>
    </React.Fragment>
  );
}

export default Sponsors;
