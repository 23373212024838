import React from "react";
import Fade from "react-reveal/Fade";
import Styles from "./Section1.module.css";
import guitarImage from "../../../Assets/initiatives/ed.jpg";
import circle from "../../../Assets/initiatives/circle.svg";
import arrowline from "../../../Assets/initiatives/longarrowline.svg";
import bg from "../../../Assets/initiatives/initiatives_bg.svg";
// import dynamic from "next/dynamic";

export default function Section1() {
  // const Scroll = dynamic(
  //   () => {
  //     return import("../../Assets/scroll");
  //   },
  //   { ssr: false }
  // );
  //   // // console.log(Scroll);
  return (
    <React.Fragment>
      <div className={Styles.mainSection1}>
        <Fade top cascade>
          <img
            src={bg}
            className={` md:h-[55vh] h-[25vh] mx-auto mt-[30vh] md:-mb-[85vh] -mb-[55vh] opacity-[0.8]`}
            alt="initiatives"
          ></img>
          <div className={Styles.mainhead + ` mx-auto text-center relative`}>
            <p className="md:mt-0 mt-20 md:my-0 -my-20">AARUUSH</p>
            <img
              src={guitarImage}
              className={
                Styles.guitar +
                ` rounded-[1000px] md:h-[55vh] h-[35vh] mx-auto md:mt-[-24vh] mt-[5vh] opacity-70 brightness-50`
              }
              alt="initiatives"
            ></img>
            <div className={Styles.initiatives + ` `}>Initiatives</div>
          </div>
        </Fade>
        {/* ========= Redo this area ===== */}
        <div className={Styles.subtext + ` flex flex-row justify between`}>
          <div className={Styles.subtextleft + ` -ml-10 mt-2`}>
            Aaruush Initiatives
          </div>
          <div className={Styles.subtextright + ` -mr-8 text-white`}>2022</div>
        </div>
        <Fade top cascade>
          {/* <Scroll /> */}
          <div id="downarrow">
            <div
              className={
                Styles.arrowgroup +
                ` absolute md:left-[8vw] md:top-[85vh] cursor-pointer group mx-auto `
              }
            >
              <div>
                <img
                  src={circle}
                  className={
                    Styles.circle +
                    ` md:group-hover:scale-110 transition group-hover:animate-pulse`
                  }
                  alt="initiatives"
                ></img>
                <img
                  src={arrowline}
                  className={
                    Styles.arrow + `  animate-bounce transition mx-auto`
                  }
                  alt="initiatives"
                ></img>
              </div>
              {/* <p
                className={`text-white pl-[13vw] group-hover:text-[#EF6522] transition -mt-3 md:block hidden`}
              >
                Scroll down{" "}
              </p> */}
              {/* <p className={`text-white pl-[13vw] group-hover:text-[#EF6522] transition md:block hidden`}>
                to see more
              </p> */}
            </div>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
}
