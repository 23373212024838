import React from "react";
// import extractColors from 'extract-colors'
// import Image from "next/image";
// import { usePalette } from "react-palette";

const Patronage = ({ logo }) => {
  //   const propimg = require("../Assets/Images/about/" + props.logo.image);
  const propimg = logo.image;

  // const { data} = usePalette(propimg);
  // if (!data){}
  const data = {
    darkVibrant: "#ef655530",
    lightVibrant: "#ef652250",
    vibrant: "#ef652270",
  };
  // console.log(data, loading, error);
  // // console.log(data.darkVibrant, data.lightVibrant)

  return (
    <div
      className={`flex hover:backdrop-brightness-[30%]  backdrop-brightness-75 flex-row justify-center align-middle cursor-pointer place-items-center h-12 md:h-24 w-20 md:w-44 rounded-lg backdrop-blur-lg bg-gradient-to-br px-6 md:px-12 mx-2 md:mx-5 py-1 my-5`}
      style={{
        backgroundImage: `linear-gradient(105.87deg,${data.darkVibrant} 10%, ${data.lightVibrant} 60%,${data.vibrant} )`,
        WebkitBackdropFilter: "brightness(50%)",
      }}
    >
      <div className=" flex flex-row justify-center align-middle place-items-center">
        <img
          className="mx-auto my-auto"
          // layout="responsive"
          width={100}
          height={100}
          src={propimg}
          alt="Pic Ring"
        />
      </div>
    </div>
  );
};

export default Patronage;
