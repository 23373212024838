// import Head from "next/head";
import Landing from "../components/Home/Home";
import ClockTower from "../components/Clocktower/Clocktower";
import API from "../Services/axios";
import { useEffect, useState } from "react";
// import vid from "../Assets/compress.mp4";
import bwa from "../Assets/bwa2.gif";
// import vid from "../Assets/BWA 2.mp4";
// import vid from "https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/videos/BWA.mp4";
export default function Home() {
  // const [events, setEvents] = useState();

  // useEffect(() => {
  //   async function getEvents() {
  //     const events = (await API.get(`/events/live`)).data;
  //     setEvents(events);
  //   }
  //   getEvents();
  // }, []);

  const [play, setPlay] = useState(true);
  function getHoursDiff(startDate, endDate) {
    const msInHour = 1000 * 60 * 60;
    console.log(Math.round(Math.abs(endDate - startDate) / msInHour));
    return Math.round(Math.abs(endDate - startDate) / msInHour);
  }

  const date = new Date();
  const date2 = localStorage.getItem("aaruushlastload");
  useEffect(() => {
    if (date2) {
      if (getHoursDiff(new Date(date), new Date(date2)) > 3) {
        // localStorage.removeItem("aaruushlastload");

        setPlay(true);
        localStorage.setItem("aaruushlastload", date);
        setTimeout(() => {
          setPlay(false);
        }, 6000);
      } else {
        setPlay(false);
      }
    } else {
      setPlay(true);
      localStorage.setItem("aaruushlastload", date);
      setTimeout(() => {
        setPlay(false);
      }, 6000);
    }
  }, []);

  return (
    <div style={{ backgroundColor: "transparent" }}>
      {play ? (
        <div>
          {/* <video
          className="w-screen h-[100vh] fixed top-0 left-0 z-[10000] transition object-cover"
          autoPlay={true}
          muted={true}
          height="100vh"
          preload="auto"
          onEnded={(e) => {
            //   console.log(e);
            // e.target.parentNode.removeChild(e.target);
            setPlay(false);
          }}
        >
          <source src={vid}></source>
        </video> */}
          <img
            src={bwa}
            className="w-screen h-[100vh] fixed top-0 left-0 z-[1000] object-contain md:object-cover"
            alt="gif"
          />
        </div>
      ) : (
        <>
          <Landing />
          <div className="bg-bgcolor">
            <ClockTower />
          </div>
        </>
      )}
    </div>
  );
}
