import React, { useState } from "react";
// import Fade from "react-reveal/Fade";
// import Styles from "./Section2.module.css";
// import guitarImage from "../../../Assets/Images/initiativesGuitar.svg"
// import circle from "../../../Assets/Images/circle.svg"
// import arrowline from "../../../Assets/Images/longarrowline.svg"
// import initiatives from "../../Assets/Json/sampledata.json";
import Card from "./Card/Card";
// import { usePalette } from "react-palette";
import { ImCross } from "react-icons/im";
import Styles from "./Section2.module.css";
// import circle from "../../Assets/Images/circle.svg";
import arrowprev from "../../../Assets/initiatives/arrowprev.svg";
import arrownext from "../../../Assets/initiatives/arrownext.svg";
import mobarrowprev from "../../../Assets/initiatives/mobarrowprev.svg";
import mobarrownext from "../../../Assets/initiatives/mobarrownext.svg";
// import useWindowDimensions from "../../Assets/window"

export default function Section1({ initiatives }) {
  const [showModal, setShowModal] = React.useState(false);
  // const [show, setshow] = useState(false);
  const [curindex, setIndex] = useState(0);
  const [count, setCount] = useState(curindex);
  var length = initiatives.length;
  // // // console.log(count!=length)
  var current = initiatives[count];
  // const img = require("../../Assets/Images/initiatives/bgimage/" +
  //   current.thumbnail);
  const img = current.image;
  // var { data, loading, error } = usePalette(img);
  // console.log(data, loading, error);
  // if (error) {
  //   data = {
  //     vibrant: "#666",
  //   };
  // }
  // const { height, width } = useWindowDimensions();
  const width = 1000;
  // // // console.log(height,width)

  function clickFunction(e) {
    // // // console.log(e);
    var modal = document.querySelector(".main-modal");
    var buttons = document.querySelector(".modal-button");
    // // // console.log(modal.contains(e.target))
    var check = modal.contains(e.target) || buttons.contains(e.target);
    // // // console.log(check)
    if (!check) setShowModal(false);
  }

  const sortedInitiatives = initiatives.sort(function (a, b) {
    //   // console.log(a.designation - b.designation);
    //   // return a.designation - b.designation;
    //   // return a.name - b.name;
    return a.si - b.si;
  });

  return (
    <React.Fragment>
      <div className="mt-[10vh] text-white font-xxl min-h-[100vh] flex flex-wrap justify-center">
        <div id="initiatives"></div>
        {initiatives &&
          initiatives
            .sort(function (a, b) {
              return a.si - b.si;
            })
            .map((initiative, index) => {
              // // // console.log(index)
              return (
                <div
                  onClick={() => {
                    // // // console.log(`Entered ${index}`);
                    setShowModal(true);
                    setCount(index);
                    setIndex(index);
                  }}
                  key={index}
                >
                  <Card
                    img={initiatives && initiative.image}
                    index={index}
                    list={initiatives}
                  />
                </div>
              );
            })}
        {showModal && (
          <>
            <div
              className="justify-center text-white items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur backdrop-brightness-[0.5] transition"
              // onClick={(e)=>{// // console.log('Click')}}
              onClick={(e) => {
                clickFunction(e);
              }}
            >
              <div className="absolute my-auto text-white flex flex-row flex-wrap justify-between w-full modal-button">
                <button
                  className="z-50 disabled:opacity-[0.2] disabled:curson-default disabled:animate-none transition"
                  disabled={count === 0}
                >
                  {count ? (
                    <>
                      <>
                        <div
                          className={
                            Styles.arrowgroupprev +
                            ` absolute cursor-pointer group`
                          }
                          onClick={() => {
                            setCount(count - 1);
                          }}
                        >
                          <img
                            src={width >= 768 ? arrowprev : mobarrowprev}
                            className={
                              Styles.arrow +
                              `  group-hover:animate-left transition mx-auto lg:w-full `
                            }
                            alt="initiatives"
                          ></img>
                        </div>
                      </>
                    </>
                  ) : (
                    <>
                      <div
                        className={Styles.arrowgroupprev + ` absolute group`}
                      >
                        <img
                          src={width >= 768 ? arrowprev : mobarrowprev}
                          className={
                            Styles.arrow + `  transition mx-auto lg:w-full`
                          }
                          alt="initiatives"
                        ></img>
                      </div>
                    </>
                  )}
                </button>
                <button className="z-50 disabled:opacity-[0.2] disabled:curson-default transition">
                  {count !== length - 1 ? (
                    <>
                      <div
                        className={
                          Styles.arrowgroupnext +
                          ` absolute cursor-pointer group`
                        }
                        onClick={() => {
                          // // // console.log(`Go to ${index-1}`)
                          setCount(count + 1);
                        }}
                      >
                        <img
                          className="group-hover:animate-right"
                          src={width >= 768 ? arrownext : mobarrownext}
                          alt="initiatives"
                        ></img>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={
                          Styles.arrowgroupnext +
                          ` absolute cursor-default group`
                        }
                      >
                        <img
                          src={width >= 768 ? arrownext : mobarrownext}
                          className={
                            Styles.arrow + `   transition mx-auto opacity-[0.2]`
                          }
                          alt="initiatives"
                        ></img>
                      </div>
                    </>
                  )}
                </button>
              </div>
              <div className="relative w-auto lg:my-6 mx-auto lg:max-w-6xl max-w-[85vw] max-h-[100vh] h-auto main-modal transition">
                {/*content*/}
                <div
                  className={` transition backdrop-blur-md rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}
                  style={{
                    backgroundColor: "#ffffff40",
                  }}
                >
                  {/*header*/}
                  <div className="flex items-start justify-between lg:p-5 p-3  rounded-lg lg:-mb-12 z-50">
                    <button
                      className=" bg-transparent border-0 text-white float-right leading-none outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <div className="max-w-sm ">
                        <span
                          className="absolute lg:right-6 right-4 text-white opacity-[0.7] h-6 w-6 text-xl cursor-pointer block outline-none focus:outline-none"
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          <ImCross />
                        </span>
                      </div>
                    </button>
                  </div>
                  {/*body*/}

                  <div className="w-fit transition relative lg:p-8 p-2 flex lg:flex-row flex-wrap flex-col mt-2 text-white">
                    <img
                      src={img}
                      className="rounded-lg lg:max-h-[50vh] max-h-[35vh] my-auto object-contain"
                      alt="initiatives"
                    />
                    <div className="flex flex-col justify-content items-center">
                      <h1 className="text-3xl font-bold capitalize">
                        {current.name === "t-summit"
                          ? current.name
                          : current.name.split("-").join(" ")}
                      </h1>
                      <p className="lg:my-4 my-1 transition lg:max-w-lg text-lg leading-relaxed lg:p-10 p-2 text-justify">
                        {current.description}
                      </p>
                    </div>
                  </div>
                  {/*footer*/}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
