/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
// import Image from "next/image";
// import Aaruush from "../Assets/Aaruush.svg";
// import { IoMdInfinite } from "react-icons/io";
import Styles from "./Home.module.css";
import style from "./Edi.module.css";

// import Htimeline from "./Htimeline";
import { BsArrowRight } from "react-icons/bs";
// import { blur } from "tailwindcss/defaultTheme";
import EventCard from "./EventCard";
import InitiativesCard from "./initiativescard";

import GwrCard from "./GwrCard";
// import Educard from "./Educard";
import Lottie from "react-lottie";
import aaruush22logo from "./aaruush22logo.json";
import infinityloop from "./infinityloop.json";
import { Link } from "react-router-dom";
// import xtimeline from "../../public/htimeline.svg";
// import ecorunbg from "../Assets/Images/gwr/ecorunbg.svg";
import API from "../../Services/axios";
import data from "../../data/data.json";

function Home({ events }) {
  const [initiatives, setinitiatives] = useState();

  useEffect(() => {
    async function getinitiatives() {
      // const initiatives = (await API.get(`/static/initiatives`)).data;
      const initiatives = data["initiatives"];
      setinitiatives(initiatives);
    }
    getinitiatives();
  }, []);
  console.log(initiatives);

  const Lottiedefaultoptions = {
    loop: false,
    autoplay: true,
    animationData: aaruush22logo,
  };
  const infinityloopoptions = {
    loop: true,
    autoplay: true,
    animationData: infinityloop,
  };
  const CertificatesData = [
    {
      image: "Cphoto.svg",
      Desc: "Lorem ipsum dolor sit amet,",
      Date: "12 Jan 2021, Monday",
    },
    {
      image: "Cphoto.svg",
      Desc: "Lorem ipsum dolor sit amet,",
      Date: "13 Jan 2021, Monday",
    },
    {
      image: "Cphoto.svg",
      Desc: "Lorem ipsum dolor sit amet,",
      Date: "14 Jan 2021, Monday",
    },
  ];
  return (
    <>
      <div className="h-[100vh]">
        <div className="lg:w-8/12 relative m-auto top-40">
          <Lottie options={Lottiedefaultoptions} />
        </div>
      </div>

      <div className="flex  justify-center my-[10vh]">
        <div
          className="w-[20vw] h-[38vh] bg-[#EF6522] blur-3xl relative bottom-[12vh] -mr-[6vw] 
          "
          style={{ borderRadius: "100%", opacity: 0.33 }}
        ></div>
        <div className="flex flex-col justify-center items-center ">
          <p className="text-white text-center text-3xl w-[60vw] pb-4 z-99 font-SFDistantGalaxy tracking-wider">
            A ray of light that births the kaleidoscope of ideas and helps rise
            in the spirit of innovation
          </p>
        </div>
        <div>
          <div
            className="w-[20vw] h-[38vh] bg-[#EF6522] blur-3xl -ml-[8vw] relative top-[2vh] "
            style={{ borderRadius: "100%", opacity: 0.33 }}
          ></div>
        </div>
      </div>

      <div
        className={`lg:w-[60vw] w-[90vw] mt-10 ${Styles.About} rounded-3xl border-y-2 border-x-2 border-orange-500 relative backdrop-blur-lg mx-auto backdrop-brightness-75`}
      >
        <div className="flex flex-col justify-center px-8 pt-8 pb-4 gap-8 text-white text-center">
          <div>
            <p className="font-SFDistantGalaxy  text-5xl text-white text-center">
              Aaruush '22
            </p>
          </div>
          <div>
            <p className="max-w-2xl mx-auto font-Montserrat text-white md:text-lg text-justify">
              The <b>16th edition of Aaruush</b> is underway. With the theme, "
              <b>Technology for Well-being</b>", we are committed to innovating
              for the betterment of the society. Staying true to our tagline,
              "Devising ideologies, Unravelling miracles", we are in the
              constant pursuit towards infinity. <br />
              <br />
              The Main Days of Aaruush '22 are going to be held from the{" "}
              <b>27th of October</b> till the <b>30th of October</b>.
            </p>
          </div>
          <hr className="bg-[#ef6522] border-none h-[2px] w-[80%] mt-5 mx-auto" />

          <div className="flex flex-col md:flex-row justify-center gap-x-8 max-w-[100%]">
            <a
              href="https://skillvertex.in"
              target="_blank"
              className="border-2 from-[#000] bg-gradient-to-tl to-[#ef652230] border-[#ef6522] hover:to-[#ef652240] py-2 px-4 m-2 flex-col outline-4  outline-black transition rounded-lg cursor-pointer max-w-[250px] gap-y-4 flex align-middle place-items-center"
            >
              Title Sponsor
              <a>
                <img
                  className="max-w-[150px]"
                  src="https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/sponsors/skillvertex-231119c7.webp"
                ></img>
              </a>
            </a>
            <a
              href="http://www.glitchh.in/"
              target="_blank"
              className="border-2 from-[#000] bg-gradient-to-tl to-[#ef652230] border-[#ef6522] hover:to-[#ef652240] py-2 px-4 m-2 flex-col outline-4  outline-black transition rounded-lg cursor-pointer max-w-[250px] gap-y-4 flex align-middle place-items-center"
            >
              Co Sponsor
              <img
                className="max-w-[150px]"
                src="https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/sponsors/glitchh-62bb419c.webp"
              ></img>
            </a>
          </div>
          {/* <div className="flex items-center justify-between relative ">
            <hr className="bg-[#ef6522] border-none h-[2px] lg:w-[20vw] md:w-[20vw] w-[10vw]" />
            <div>
              <p className="text-white md:text-lg text-sm font-Montserrat tracking-widest">
                Know more about us
              </p>
            </div>
            <Link to="/about">
              <div
                className={`${Styles.circle}  ml-5 w-[5vh] h-[5vh] cursor-pointer group `}
              >
                <BsArrowRight
                  className={` text-4xl text-[#ef6522] cursor-pointer relative  right-[2vh]
                  group-hover:animate-right `}
                />
              </div>
            </Link>
          </div> */}
        </div>
      </div>

      <div className={Styles.scroll_container}>
        <div
          className={`${
            Styles.legacy + " " + Styles.scroll_text
          } tracking-[4vh] -ml-[8vw] whitespace-nowrap`}
        >
          ABOUT <span className="ml-[8vw]">US</span>
        </div>
      </div>

      <div
        className={`lg:w-[60vw] w-[90vw] ${Styles.About} rounded-r-3xl border-y-2 border-r-2 border-orange-500  relative bottom-[20vh] backdrop-blur-sm`}
      >
        <div className="flex flex-col justify-center px-8 pt-8 pb-4 gap-8">
          <div>
            <p className="font-SFDistantGalaxy  text-5xl text-white">
              ABOUT US
            </p>
          </div>

          <div>
            <p className="max-w-xl font-Montserrat text-white md:text-lg text-base">
              As one of the largest techno-management fests in the country,
              Aaruush is a one-stop destination for brilliant and like minded
              individuals who shall spearhead the future. A stellar legacy that
              stands for the first rays of sun has embarked a journey of
              crossing milestones every year.
              <br /> Aaruush is the student-run national level Techno-Management
              Fest of SRM Institute of Science and Technology. Since its
              inception in 2007, the stellar legacy of the fest was inaugurated
              by late President, Dr A. P. J. Abdul Kalam, laying the foundation
              and paving the way for the milestones to overcome in the future.
              This year marks the sixteenth edition of Aaruush.
            </p>
          </div>
          <div className="flex items-center justify-between relative ">
            <hr className="bg-[#ef6522] border-none h-[2px] lg:w-[20vw] md:w-[20vw] w-[10vw]" />
            <div>
              <p className="text-white md:text-lg text-sm font-Montserrat tracking-widest">
                Know more about us
              </p>
            </div>
            <Link to="/about">
              <div
                className={`${Styles.circle}  ml-5 w-[5vh] h-[5vh] cursor-pointer group `}
              >
                <BsArrowRight
                  className={` text-4xl text-[#ef6522] cursor-pointer relative  right-[2vh]
                  group-hover:animate-right `}
                />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className={Styles.scroll_container}>
        <div
          className={`${
            Styles.legacy + " " + Styles.scroll_text
          } tracking-[4vh] -ml-[8vw] whitespace-nowrap`}
        >
          Timeline
        </div>
      </div>

      <div
        className={`lg:w-[60vw] w-[90vw] ${Styles.About} rounded-l-3xl border-y-2 border-l-2 border-orange-500  relative bottom-[20vh] ml-auto backdrop-blur-sm`}
      >
        <div className="flex flex-col justify-center px-8 pt-8 pb-4 gap-8">
          <div className="flex item-center justify-between">
            <p className="font-SFDistantGalaxy  text-5xl text-white">
              Timeline
            </p>
            <hr className="bg-[#ef6522] border-none h-[2px] lg:w-[35vw] md:w-[35vw] w-[40vw] my-auto" />
          </div>
          <div className="flex justify-between flex-col">
            <div>
              <div className={`${style.edition} leading-none`}>
                <p
                  className={` text-white text-[10vh] m-0 lg:text-[12vh] 2xl:text-[15vh]`}
                >
                  15th
                </p>
                <p
                  className={` text-orange-600 text-[10vh] m-0 lg:text-[12vh] 2xl:text-[15vh]`}
                >
                  EDITION
                </p>
              </div>
              <p className={` text-white text`}>
                Aaruush '21 hosted a series of stellar events, workshops and
                hosted eminent personalities like Dr.Anil Bharadwaj, Former
                director at SPL, Dr S.K. Singh, Director of NCDC, Dr
                Ramachandran Balasubramanian, renowned Padma Shri awardee and
                renowned celebrity Mr Sunny Hinduja.
              </p>
            </div>

            <div className="flex items-center justify-end relative ">
              <div>
                <p className="text-white md:text-lg text-sm font-Montserrat tracking-widest">
                  View full timeline
                </p>
              </div>
              <Link to="/timeline">
                <div
                  className={`${Styles.circle}  ml-5 w-[5vh] h-[5vh] cursor-pointer group `}
                >
                  <BsArrowRight
                    className={` text-4xl text-[#ef6522] cursor-pointer relative  right-[2vh]
                  group-hover:animate-right `}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.scroll_container}>
        <div
          className={`${
            Styles.legacy + " " + Styles.scroll_text
          } tracking-[8vh]`}
        >
          EVENTS
        </div>
      </div>

      <div
        className={`lg:w-[60vw] w-[90vw]  ${Styles.About} rounded-r-3xl border-y-2 border-r-2 border-orange-500 relative bottom-[16vh] backdrop-blur-md`}
      >
        <div className="flex flex-col justify-center md:px-8 px-4  pt-8 pb-4 gap-8">
          <p className="font-SFDistantGalaxy text-5xl text-white">EVENTS</p>
          <div>
            <p className="max-w-4xl font-Montserrat text-white lg:text-xl md:text-lg text-base">
              To amplify the feeling of the fest, we have had some spectacular
              events, conducted by Team Aaruush that collectively works towards
              the upliftment of the students.
            </p>
          </div>

          {/* <div className="w-[80vw] lg:w-[55vw] overflow-auto">
            <div className="flex items-center gap-8 ">
              {events &&
                events.map((data, idx) => <EventCard data={data} key={idx} />)}
            </div>
          </div> */}

          {/* <div className="flex items-center justify-between ">
            <hr className="bg-[#ef6522] border-none h-[2px] lg:w-[20vw] md:w-[20vw] w-[20vw]" />
            <div>
              <p className="text-white lg:text-lg text-sm font-Montserrat tracking-widest">
                View all live events
              </p>
            </div>
            <Link to="events">
              <div
                className={`${Styles.circle}  ml-5 w-[5vh] h-[5vh] cursor-pointer group `}
              >
                <BsArrowRight
                  className={`text-4xl text-[#ef6522] cursor-pointer relative  right-[2vh]
                  group-hover:animate-right`}
                />
              </div>
            </Link>
          </div> */}
        </div>
      </div>

      <div className={Styles.scroll_container}>
        <div
          className={`${
            Styles.legacy + " " + Styles.scroll_text
          } tracking-[4vh] whitespace-nowrap`}
        >
          OUR GWR
        </div>
      </div>

      <div className="text-white font-SFDistantGalaxy md:text-[10vh] text-[8vh] relative bottom-[30vh] text-left pl-10 tracking-wide">
        OUR GWR
      </div>

      <div className="flex flex-col md:flex-row relative md:bottom-[15vw] bottom-[30vw] justify-around items-start px-5">
        <div className="h-full">
          <GwrCard
            bg={"ecorun"}
            year={"Aaruush '17"}
            name={"SRM Eco-Run"}
            desc={
              "Aaruush’17 witnessed history in the making by conducting the biggest Eco-Run on the occasion of 71st Independence Day. "
            }
          />
        </div>
        <div className="h-full">
          <GwrCard
            bg={"gwr"}
            year={"Aaruush '19"}
            name={"Largest Software Lesson"}
            desc={
              "Aaruush’19  was in the limelight for setting an Adobe Lightroom lesson with the highest record of participants."
            }
          />
        </div>
      </div>

      <div className={Styles.scroll_container}>
        <div
          className={`${
            Styles.legacy + " " + Styles.scroll_text
          } tracking-[4vh] -ml-[12vw]`}
        >
          INITIATIVES
        </div>
      </div>
      <div
        className={`lg:w-[60vw] w-[90vw] ${Styles.About} rounded-r-3xl border-y-2 border-r-2 border-orange-500 relative bottom-[16vh] backdrop-blur-md`}
      >
        <div className={`${Styles.aboutBg}`}></div>
        <div className="flex flex-col justify-center md:px-8 px-4 pt-8 pb-4 gap-8">
          <div>
            <p className="font-SFDistantGalaxy  text-5xl text-white">
              INITIATIVES
            </p>
          </div>
          <div>
            <p className="max-w-4xl font-Montserrat text-white mg:text-xl text-base">
              With an aim to collectively work towards the holistic development
              of the student body, Team Aaruush takes several initiatives to
              provide the best for the student community.
            </p>
          </div>
          <div className="w-[80vw] lg:w-[55vw] overflow-scroll">
            <div className="flex justify-start items-center gap-3 overflow-scroll">
              {initiatives?.map((data, idx) => {
                if (
                  data.name == "aaruush-educates" ||
                  data.name == "t-summit" ||
                  data.name == "srm-run"
                )
                  return (
                    <InitiativesCard
                      key={idx}
                      img={
                        "https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/initiatives/tsummitnew-4c64d0e5.webp"
                      }
                      data={data}
                    />
                  );
              })}
            </div>
          </div>
          <div className="flex items-center justify-between align-center">
            <hr className="bg-[#ef6522] border-none h-[2px] lg:w-[20vw] md:w-[20vw] w-[20vw]" />
            <div>
              <p className="text-white md:text-lg text-sm font-Montserrat tracking-widest">
                View all Initiatives
              </p>
            </div>
            <Link to="initiatives">
              <div
                className={`${Styles.circle}  ml-5 w-[5vh] h-[5vh] cursor-pointer group `}
              >
                <BsArrowRight
                  className={`text-4xl text-[#ef6522] cursor-pointer relative  right-[2vh]
                  group-hover:animate-right`}
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
