// import Head from "next/head";
import React from "react";
import SponsorHead22 from "../components/Sponsors/Sponsora22";
import SponsorHeadprevious from "../components/Sponsors/PreviousSponsors";
import SponsorsList from "../components/Sponsors/SponsorList";
import API from "../Services/axios";
import { useEffect, useState } from "react";
import Sponsors from "../components/OldSponsors/Sponsors";
import Contact from "../components/Sponsors/contact";
import data from "../data/data.json";
export default function Sponsorpage() {
  // const sponsordata = groupBy(props.sponsors, "category");
  // console.log(props);
  // // console.log(props.sponsors,props.category)

  const [sponsors, setSponsors] = useState();
  const [category, setCategory] = useState();
  useEffect(() => {
    async function getSponsors() {
      // const sponsors = (await API.get(`/static/sponsors`)).data;
      const sponsors = data["sponsors"];
      // console.log(sponsors);
      setSponsors(sponsors);
    }
    getSponsors();
  }, []);

  useEffect(() => {
    async function getCategory() {
      // const category = (await API.get(`/static/SponsorsCategory`)).data;\
      const category = data["sponsorcategory"];
      // console.log(category);
      setCategory(category);
    }
    getCategory();
  }, []);

  const aaruush22sponsors = sponsors?.filter((data) => {
    if (data.category === "9aa252e5") {
      return data;
    }
    return null;
  });
  const previoussponsors = sponsors?.filter((data) => {
    if (data.category === "d07bc1e9") {
      return data;
    }
    return null;
  });

  const newArr = sponsors?.filter((data) => {
    if (data.category != "9aa252e5" && data.id != "d07bc1e9") {
      return data;
    }
    return null;
  });
  const newCat = category?.filter((data) => {
    if (data.id != "9aa252e5" && data.id != "d07bc1e9") {
      return data;
    }
    return null;
  });
  return (
    <React.Fragment>
      <div className={"bg-bgcolor min-h-[100vh] scroll-smooth pb-[2vh]"}>
        {sponsors && (
          <>
            <SponsorHead22 />
            {/* <SponsorsList sponsors={aaruush22sponsors} /> */}
            <Sponsors sponsors={newArr} category={newCat}></Sponsors>
            <SponsorHeadprevious />
            <SponsorsList sponsors={previoussponsors} />
            <Contact></Contact>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
