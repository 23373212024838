import React from "react";
import Styles from "./Contact.module.css";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
// import { TiTick } from "react-icons/ti";

export default function Contact() {
  return (
    <React.Fragment>
      <div className={`pb-20 pt-20 bg-bgcolor ${Styles.main}`}>
        <div className={Styles.heading}>Contact Us</div>
        <div className={Styles.desc}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
        <div className="md:w-[35%] w-[65%] mx-auto">
          <div
            className={
              "mt-5 flex flex-row flex-wrap text-2xl justify-between basis-[75%] transition "
            }
          >
            <a
              className={Styles.orangelink}
              href="https://www.linkedin.com/company/aaruush-srm-ist/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              className={Styles.orangelink}
              href="https://www.instagram.com/aaruush_srm/"
              target="_blank"
              rel="noreferrer"

            >
              <FaInstagram />
            </a>
            <a
              className={Styles.orangelink}
              href="https://www.youtube.com/c/AaruushSRMIST"
              target="_blank"
              rel="noreferrer"

            >
              <FaYoutube />
            </a>
            <a
              className={Styles.orangelink}
              href="https://twitter.com/aaruushsrmist"
              target="_blank"
              rel="noreferrer"

            >
              <FaTwitter />
            </a>
            <a
              className={Styles.orangelink}
              href="https://aaruushblog.wordpress.com/"
              target="_blank"
              rel="noreferrer"

            >
              <FaWordpress />
            </a>
          </div>
        </div>

        <form
          className={
            "border border-white min-h-[60vh] md:w-[70vw] w-[90vw] mx-auto mt-5 p-3 md:px-10 px-4 rounded"
          }
        >
          <div className="md:flex md:flex-row md:flex-wrap md:justify-around pt-4 mx-auto">
            <fieldset className="border border-solid border-gray-300 px-2 rounded-lg md:w-[45%] w-[95%] mx-auto mt-4">
              <legend className="text-white mx-8 px-5">First Name</legend>
              <input
                type="text"
                name="firstname"
                className="bg-transparent text-white outline-none focus:outline-none focus:ring-0 focus:border-0 w-full pb-2 pl-4"
              />
            </fieldset>
            <fieldset className="border border-solid border-gray-300 px-2 rounded-lg md:w-[45%] w-[95%] mx-auto mt-4 ">
              <legend className="text-white mx-8 px-5">Last Name</legend>
              <input
                type="text"
                name="lastname"
                className="bg-transparent text-white outline-none focus:outline-none focus:ring-0 focus:border-0 w-full pb-2 pl-4"
              />
            </fieldset>
          </div>
          <fieldset className="border border-solid border-gray-300 px-2 rounded-lg w-[95%] mx-auto mt-4">
            <legend className="text-white mx-8 px-5">Email</legend>
            <input
              type="email"
              name="email"
              className="bg-transparent text-white outline-none focus:outline-none focus:ring-0 focus:border-0 w-full pb-2 pl-4"
            />
          </fieldset>
          <fieldset className="border border-solid border-gray-300 px-2 rounded-lg w-[95%] mx-auto mt-4">
            <legend className="text-white mx-8 px-5">Phone</legend>
            <input
              type="tel"
              name="phone"
              className="bg-transparent text-white outline-none focus:outline-none focus:ring-0 focus:border-0 w-full pb-2 pl-4"
            />
          </fieldset>
          <fieldset className="border border-solid border-gray-300 px-2 rounded-lg w-[95%] mx-auto mt-4 mb-4">
            <legend className="text-white mx-8 px-5">Message</legend>
            <textarea
              name="message"
              className="bg-transparent text-white outline-none focus:outline-none focus:ring-0 focus:border-0 w-full pb-2 pl-4 resize-none h-48 "
            ></textarea>
          </fieldset>
          <button
            type="submit"
            className="bg-[#EF6522] text-white px-12 py-1 rounded-lg md:ml-[83%] ml-[45%] mb-4"
          >
            Submit
          </button>
        </form>
      </div>
    </React.Fragment>
  );
}
