/* eslint-disable no-unused-vars */
import React, { useRef, useState, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

import Clocktower from "./Model2";

const Scene = ({ setPosition }) => {
  const scene = useRef();
  useFrame((star) => {
    const t = star.clock.getElapsedTime();
    const y = window.pageYOffset;
    scene.current.rotation.y = y * 0.002;
  });
  return (
    <group ref={scene}>
      <Clocktower />
    </group>
  );
};

export default function App() {
  const [Position, setPosition] = useState("fixed");

  return (
    <Canvas
      style={{
        height: "100vh",
        width: "100vw",
        position: Position,
        top: 0,
        zIndex: "-1",
      }}
      className="bg-bgcolor "
    >
      {/* <OrbitControls /> */}
      {/* <fog attach="fog" args={["#363636", 4, 7, 4]} /> */}
      <ambientLight intensity={1} />
      <Suspense fallback={null}>
        <Scene setPosition={setPosition} />
      </Suspense>
    </Canvas>
  );
}
