import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import aaruush_logo from "../../Assets/Navbar/aaruush-header.png";
import hamburger from "../../Assets/Navbar/Hamburger.svg";
import MobileNavbar from "./MobileNavbar";
import Styles from "./navbar.module.css";
import { BsFillCircleFill } from "react-icons/bs";
import { GoChevronDown } from "react-icons/go";
import { Link } from "react-router-dom";
// import Link from "next/link";
import API from "../../Services/axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import usererricon from "../../Assets/Navbar/user.svg";
// import { MdOutlineDashboard } from "react-icons/md";
// import { CgProfile } from "react-icons/cg";
import { FaSignOutAlt } from "react-icons/fa";
// import { Auth } from "aws-amplify";
// import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
export default function Navbar() {
  const [userdata, setData] = useState();
  const [user, setuser] = useState();
  const location = useLocation();
  // useEffect(() => {
  //   checkUser();
  //   async function checkUser() {
  //     try {
  //       const user = await Auth.currentAuthenticatedUser();
  //       setData(user.attributes);
  //     } catch {
  //       Cookies.remove("accessToken");
  //     }
  //   }
  // }, []);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const addgetUser = async () => {
  //     if (userdata) {
  //       const user = (
  //         await API.post("/users", {
  //           name: userdata.name,
  //           email: userdata.email,
  //           image: userdata.picture,
  //         })
  //       ).data;
  //       Cookies.set("accessToken", user.accessToken, { expires: 5 });
  //       const user_data = (
  //         await API.get(`/users/${user.email}`, {
  //           headers: {
  //             Authorization: user?.accessToken || "",
  //           },
  //         })
  //       ).data;
  //       delete user_data.pk;
  //       delete user_data.sk;
  //       setuser(user_data);
  //       dispatch(setUser(user_data));
  //     }
  //   };
  //   addgetUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userdata]);

  const navlinks = [
    "home",
    "about",
    "events",
    "gallery",
    "timeline",
    "initiatives",
    "sponsors",
    "team",
  ];
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // const location = useLocation();
  const [path, setpath] = useState(window.location.href);

  useEffect(() => {
    setpath(location.pathname.split("/")[1]);
  }, [location.pathname]);
  console.log(path.toLowerCase());
  const [x, setX] = useState(100);
  // const handleScroll = () => {
  //   // const position = window.pageYOffset;
  //   // var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   var doc = document.documentElement;
  //   var position = doc.scrollTop;
  //   // console.log(doc.scrollTop);
  //   // console.log(top);
  //   console.log(position);
  //   document.addEventListener("scroll", () => {
  //     var header = document.querySelector("header");
  //     header.classList.toggle("shadow-xl", position > 50);
  //     header.classList.toggle("bg-bgcolor", position > 50);
  //     header.classList.toggle("backdrop-blur-lg", position > 50);
  //   });
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // console.log
  return (
    <header className="">
      <MobileNavbar
        x={x}
        setX={setX}
        path={path}
        capitalizeFirstLetter={capitalizeFirstLetter}
        navlinks={navlinks}
        userdata={userdata}
        user={user}
      />

      <div className={`hidden lg:block`}>
        <div className="flex justify-between items-center p-4">
          <div className="flex gap-4 items-center">
            <img src={aaruush_logo} alt="logo" className="w-96"></img>
          </div>

          <ul className="text-white text-sm lg:text-lg md:text-md gap-2 xl:gap-3 2xl:gap-8 flex flex-row">
            {navlinks.map((data, idx) => (
              <Link key={idx} to={data === "home" ? "/" : `/${data}`}>
                {path.toLowerCase() ===
                `/${data === "home" ? "" : data}`.toLowerCase() ? (
                  <li
                    onLoad={() => {
                      console.log("hello");
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      color: "#EF6522!important",
                      cursor: "pointer",
                    }}
                  >
                    {capitalizeFirstLetter(data)}
                  </li>
                ) : (
                  <li
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    className="hover:text-[#EF6522]"
                  >
                    {capitalizeFirstLetter(data)}
                  </li>
                )}
              </Link>
            ))}
            <a
              target="_blank"
              href="https://cap.aaruush.org/"
              rel="noopener noreferrer"
            >
              <li
                style={{
                  cursor: "pointer",
                }}
                className="hover:text-[#EF6522]"
              >
                CAP
              </li>
            </a>
          </ul>
          <div className="flex flex-row">
            <Link to="/liveevents">
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className={
                  Styles.btn +
                  " h-10 mr-5 text-blck bg-white flex flex-row justify-center item-center"
                }
              >
                <span className="my-auto pl-2 mr-2 text-sm animate-[pulse_1s_infinite] text-[#D82725]">
                  <BsFillCircleFill />
                </span>
                <span className="my-auto pl-1 mr-2 text-md font-bold">
                  Live Events
                </span>
              </button>
            </Link>

            {/* {user && user ? (
              <Menu as="div" className="relative inline-block text-left z-50">
                {({ open }) => (
                  <Fragment>
                    <div>
                      <Menu.Button className="relative inline-flex justify-center w-full rounded-r-lg pr-3 pl-0 rounded-l-3xl group bg-[#565656] text-white focus:outline-none focus:shadow-sm hover:bg-[#444444] transition text-lg">
                        <img
                          src={user?.image}
                          className="w-10 mr-2 rounded-full cursor-pointer mx-auto my-auto"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = usererricon.src;
                          }}
                          alt="user"
                          referrerPolicy="no-referrer"
                        />
           

                        <GoChevronDown
                          className={`-mr-1 ml-2 h-5 w-5 my-auto fill-[#EF6522] transition duration-200 ${
                            open ? "rotate-180 ease-in-out" : ""
                          }`}
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit z-50 rounded-md shadow-lg bg-[#444444] ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1 text-white flex flex-col justify-center items-left content-center px-3">
                          <Menu.Item>
                            <div className="flex p-3 justify-center items-start">
                              <div className="flex flex-col border-r-2 border-slate-50 pr-3">
                                <img
                                  src={user?.image}
                                  className="w-20 mr-2 rounded-full cursor-pointer mx-auto my-auto"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget = usererricon;
                                  }}
                                  alt="user"
                                  referrerPolicy="no-referrer"
                                />
                                <div
                                  onClick={() => {
                                    Auth.signOut();
                                    Cookies.remove("accessToken");
                                  }}
                                  className="cursor-pointer hover:text-[#EF6522] transition mt-2 mb-2"
                                >
                                  <div className="hover:text-[#EF6522] transition cursor-pointer mt-2 flex flex-nowrap justify-left">
                                    <span className="my-auto mr-2 text-[#EF6522]">
                                      <FaSignOutAlt />
                                    </span>
                                    <span className="">Logout</span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col pl-3 justify-between h-[100%]">
                                <p className="whitespace-nowrap mb-2 capitalize">
                                  Name : {user?.name}
                                </p>
                                <p className="whitespace-nowrap mb-2">
                                  Email : {user?.email}
                                </p>
                                <p
                                  className="whitespace-nowrap mb-2"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user?.aaruushId
                                    );
                                    toast.info(
                                      "Your Aaruush-ID is copied to your clipboard"
                                    );
                                  }}
                                >
                                  Aaruush-ID :{" "}
                                  <span className="text-[#EF6522] cursor-pointer font-bold">
                                    {user?.aaruushId}
                                  </span>
                                </p>
                          
                              </div>
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Fragment>
                )}
              </Menu>
            ) : (
              <button
                className="py-2 px-4 h-10 rounded bg-[#EF6522] text-white hover:bg-[#ef6622c4] transition"
                onClick={() => {
                  Auth.federatedSignIn({ provider: "Google" });
                }}
              >
                Register
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="md:block lg:hidden sm:block">
        <div className="flex justify-between items-center p-4">
          <div className="flex gap-4 items-center">
            <img src={aaruush_logo} alt="logo" width={400}></img>
          </div>
          <div className="flex gap-4 items-center">
            <div
              onClick={() => {
                setX(0);
              }}
            >
              <img
                src={hamburger}
                alt="logo"
                className={
                  "select-none active:outline-none cursor-pointer active:ring-0 active:border-0 focus:outline-none focus:ring-0 focus:border-0 " +
                  Styles.unselect
                }
                width={40}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
