// import Image from "next/image";
import React, { Fragment } from "react";
// import { useState } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import Styles from "./navbar.module.css";
// import aaruush_logo from "../Assets/aaruush-header.png";
import CloseButton from "../../Assets/Navbar/CloseButton.svg";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Menu, Transition } from "@headlessui/react";
import { GoChevronDown } from "react-icons/go";
// import { MdOutlineDashboard } from "react-icons/md";
// import { CgProfile } from "react-icons/cg";
import { FaSignOutAlt } from "react-icons/fa";
import usererricon from "../../Assets/Navbar/user.svg";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const MobileNavbar = ({
  x,
  setX,
  navlinks,
  path,
  capitalizeFirstLetter,
  userdata,
  user,
}) => {
  const handleOut = () => {
    setX(100);
  };

  return (
    <div
      id="mobnav"
      className={`${Styles.MobileNavbar} lg:hidden fixed flex-basis flex-row  w-full  h-full z-[100] flex transition duration-500 `}
      style={{ transform: `translateX(${x}vw)` }}
    >
      <div
        className="  basis-2/6 opacity-50 bg-bgcolor"
        onClick={handleOut}
      ></div>
      <div className={`bg-bgcolor basis-4/6 w-full h-full border-l-4  `}>
        <div className="flex flex-row  mb-3 mx-3 place-content-between mt-5">
          {/* <img
            src={aaruush_logo}
            alt="logo"
            className="col-span-2 row-span-2 my-auto w-40"
          ></img> */}
          {/* <div className="grid grid-cols-2 px-5">
            {user && user ? (
              <Menu as="div" className="z-50 mr-2">
                {({ open }) => (
                  <Fragment>
                    <div>
                      <Menu.Button className="flex justify-center items-center rounded-r-lg sm:pr-3 w-fit pl-0 rounded-l-3xl group bg-[#565656] text-white focus:outline-none focus:shadow-sm hover:bg-[#444444] transition text-lg">
                        <img
                          src={user?.image}
                          className="w-10 mr-2 rounded-full cursor-pointer mx-auto my-auto"
                          alt="user"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget = usererricon;
                          }}
                          referrerPolicy="no-referrer"
                        />

                        <GoChevronDown
                          className={`sm:-mr-1 sm:ml-2 h-5 w-5 my-auto fill-[#EF6522] transition duration-200 ${
                            open ? "rotate-180 ease-in-out" : ""
                          }`}
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="lg:origin-top-right origin-center absolute lg:right-0 left-[10%] mt-2 w-fit z-50 rounded-md shadow-lg bg-[#444444] ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <div className="flex lg:flex-row flex-col p-3 justify-center lg:items-start items-center text-white">
                            <div className="flex flex-col lg:border-r-2 border-b-2 border-slate-50 pr-3 w-full lg:w-fit justify-center items-center">
                              <img
                                src={user?.image}
                                className="w-20 lg:mr-2 rounded-full cursor-pointer lg:mx-auto lg:my-auto"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget = usererricon;
                                }}
                                alt="user"
                                referrerPolicy="no-referrer"
                              />
                              <div
                                onClick={() => {
                                  Auth.signOut();
                                  Cookies.remove("accessToken");
                                }}
                                className="cursor-pointer hover:text-[#EF6522] transition mt-2 mb-2"
                              >
                                <div className="hover:text-[#EF6522] transition cursor-pointer mt-2 flex flex-nowrap justify-left">
                                  <span className="my-auto mr-2 text-[#EF6522]">
                                    <FaSignOutAlt />
                                  </span>
                                  <span className="">Logout</span>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col pl-3 justify-between h-[100%]">
                              <p className="whitespace-nowrap mb-2 capitalize">
                                Name : <span>{user.name}</span>
                              </p>
                              <p className="whitespace-nowrap mb-2">
                                Email : <span>{user.email}</span>
                              </p>
                              <p
                                className="whitespace-nowrap mb-2"
                                onClick={() => {
                                  navigator.clipboard.writeText(user.aaruushId);
                                  toast.info(
                                    "Your Aaruush-ID is copied to your clipboard"
                                  );
                                }}
                              >
                                Aaruush-ID :{" "}
                                <span className="text-[#EF6522] cursor-pointer font-bold">
                                  {user.aaruushId}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Fragment>
                )}
              </Menu>
            ) : (
              <button
                className="mr-3 h-10 rounded bg-[#EF6522] text-white hover:bg-[#ef6622c4] transition"
                onClick={() => {
                  Auth.federatedSignIn({ provider: "Google" });
                }}
              >
                Register
              </button>
            )}

            
          </div> */}
          <Link to="/liveevents">
              <button
                className={
                  Styles.btn +
                  " h-10 w-full mr-5 text-black bg-white flex flex-row justify-center item-center"
                }
              >
                <span className="my-auto pl-2 mr-2 text-sm animate-[pulse_1s_infinite] text-[#D82725]">
                  <BsFillCircleFill />
                </span>
                <span className="my-auto pl-2 mr-2 text-md font-bold">
                  Live Events
                </span>
              </button>
            </Link>

          <div
            className="outline-none col-start-3 cursor-pointer justify-self-end	w-8 h-8 row-start-1 row-span-2"
            onClick={handleOut}
          >
            <img
              className="w-8 h-8 hover:rotate-0 active:rotate-0 transition"
              src={CloseButton}
              alt="logo"
              style={{
                transform: `rotate(${x - 90}deg)`,
                transition: "0.5s ease-in-out",
                transitionDelay: "0.4s",
              }}
            ></img>
          </div>
        </div>

        <div>
          <ul>
            {navlinks.map((data, idx) => (
              <Link key={idx} to={data === "home" ? "/" : `/${data}`}>
                {path === `/${data === "home" ? "" : data}` ? (
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <p className="text-[#EF6522] transition m-2 pb-1 px-5 font-bold  text-2xl trackin-loose leading-loose">
                      {capitalizeFirstLetter(data)}
                    </p>
                  </li>
                ) : (
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <p className="text-white transition m-2 pb-1 px-5 font-bold text-2xl trackin-loose leading-loose">
                      {capitalizeFirstLetter(data)}
                    </p>
                  </li>
                )}
              </Link>
            ))}
            <a target="_blank" href="https://cap.aaruush.org/">
              <li>
                <p className="text-white transition m-2 pb-1 px-5 font-bold  text-2xl trackin-loose leading-loose">
                  CAP
                </p>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
