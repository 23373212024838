import React from 'react';
import Patrons from './Patron';
import list from '../../../Assets/About/samplepatrons.json'
import Styles from "./patrons.module.css"
import Fade from "react-reveal/Fade"

const Section6 = () => {

    return (
        <div className={`my-20 grid pt-32 md:pt-2 ${Styles.patrons_container}`}>
          <Fade bottom cascade>
            <div className='row-start-1 row-end-1 col-start-1 col-end-1'>
              <h2 className='font-SFDistantGalaxy  text-[32px] md:text-[48px] text-white text-center'>Our Patrons</h2>
            </div>
            <div className={`row-start-1 row-end-1 col-start-1 col-end-1 md:mt-20 mt-20 mx-auto flex flex-row flex-wrap justify-center z-[1] pb-20 `}>
              {list.map((img,idx) => {
                return (
                  <Patrons key = {idx} image={img} />
                )
              })}
            </div>
            </Fade>
          </div>
    );
}

export default Section6;
