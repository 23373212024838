import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../Services/axios";
import { Document, Page } from "react-pdf";
import { Helmet } from "react-helmet";

// import samplePDF from './test.pdf';

const Verify = () => {
  const { id } = useParams();
  const [cert, setCert] = useState();
  // const [file, setFile] = useState();
  useEffect(() => {
    async function verify() {
      // const certificate = await fetch(
      //   `https://api.aaruush.org/api/v1/verify/${id}`
      // ).then((res) => res.json());
      // // console.log(data);
      const certificate = (await API.get(`/verify/${id}`)).data;
      console.log(certificate, typeof certificate);
      if (typeof certificate === "object") {
        setCert(certificate);
        var metatitle = document.createElement("meta");
        var metaimg = document.createElement("meta");
        var metaurl = document.createElement("meta");
        var metadescription = document.createElement("meta");
        metatitle.name = "og:title";
        metatitle.content = "Certificate for Aaruush '22";
        metaimg.name = "og:image";
        metaimg.content =
          "https://secure.gravatar.com/blavatar/5f66d2f8c7d310f800665fdca6e14ff3?s=240";
        metaurl.name = "og:url";
        metaurl.content = cert?.link;
        metadescription.name = "og:description";
        metadescription.content = `Certificate issued to ${cert?.name} for ${cert?.event} at Aaruush '23`;
        console.log(document.getElementsByTagName("head")[0]);
        document.getElementsByTagName("head")[0].appendChild(metatitle);
        document.getElementsByTagName("head")[0].appendChild(metaimg);
        document.getElementsByTagName("head")[0].appendChild(metaurl);
        document.getElementsByTagName("head")[0].appendChild(metadescription);

        // setFile(certificate.link);
      }
    }
    verify();
  }, [id]);
  //   console.log(id);
  // console.log(file);
  return (
    <div className="w-screen min-h-screen h-max bg-[#181818] pb-24 text-white">
      <div className="pt-[20vh] px-10 mx-auto text-center w-screen ">
        <div className=" pb-[5vh]">
          {cert ? (
            <div className="text-white ">
              <div className="bg-[#ffffff20] relative backdrop-blur-md rounded-lg w-[80vw] md:w-[60vw] mx-auto mb-5 px-5 py-2 shadow-md drop-shadow-md">
                <p className="text-lg text-green-400">
                  The Certificate is valid.
                </p>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/7595/7595571.png"
                  className="absolute top-0 right-0 md:h-20 md:w-20 w-10 h-10"
                />
                <div className="">
                  <p>Issued to: {cert?.name}.</p>
                  <p className="">Issued on: {cert?.date}.</p>
                  <p className="">Issued for: {cert?.event}.</p>
                </div>
              </div>
              <a
                href={cert.link}
                className="mt-10 px-5 py-2 bg-[#ef6522] text-white rounded-lg"
              >
                Download PDF
              </a>
            </div>
          ) : (
            <h1 className="text-red-500 text-lg">
              The certificate is not valid.
            </h1>
          )}
        </div>
        <object
          data={cert?.link}
          type="application/pdf"
          className="w-[70%] h-[70vh] mx-auto"
        >
          <iframe src={cert?.link}>Err</iframe>
        </object>
        {/* {file} */}
        {/* <Document file={file}>
          <Page pageNumber={1} />
        </Document> */}
      </div>
    </div>
  );
};

export default Verify;
