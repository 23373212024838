// import Head from "next/head";
import React from "react";
import Fade from "react-reveal/Fade";
// import axios from 'axios'
// import Styles from "../components/initiatives/Section1/Section1.module.css";
import Section1 from "../components/initiatives/Section1/Section1";
import Section2 from "../components/initiatives/Section2/Section2";
// import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import API from "../Services/axios";
import data from "../data/data.json";
export default function Initiativespage() {
  // const container = {
  //   show: {
  //     transition: {
  //       delayChildren: 0.5,
  //       staggerDirection: -1,
  //       type: "spring",
  //       bounce: 0.4,
  //       duration: 0.8,
  //     },
  //   },
  // };
  const [initiatives, setinitiatives] = useState();

  useEffect(() => {
    async function getinitiatives() {
      // const initiatives = (await API.get(`/static/initiatives`)).data;
      const initiatives = data["initiatives"];
      setinitiatives(initiatives);
    }
    getinitiatives();
  }, []);
  console.log(initiatives);
  // const sortedInitiatives = initiatives.sort(function (a, b) {
  //   // console.log(a.designation - b.designation);
  //   // return a.designation - b.designation;
  //   // return a.name - b.name;
  //   return a.si - b.si;
  // });
  return (
    <React.Fragment>
      <div
        className={
          "bg-bgcolor min-h-[100vh] scroll-smooth overflow-hidden pt-[15vh]"
        }
      >
        {/* <Fade top cascade delay={1000}> */}
        <Fade top cascade>
          <Section1 />
        </Fade>

        {initiatives && <Section2 initiatives={initiatives} />}

        {/* </Fade> */}
        <div id="downbelow"></div>
      </div>
    </React.Fragment>
  );
}
