import React, { useState, useEffect } from "react";
// import Head from "next/head";

// import Image from "next/image";
import { Fragment } from "react";
import Hero from "../Assets/About/Hero.png";
// import picRing from "../Assets/About/picRing.png";
// import Img from "../Assets/About/OurLegacy.png";
// import ourTheme from "../Assets/About/ourTheme.png";
import srmmerge from "../Assets/About/srmmerge.svg";
// import motoQuote from "../Assets/About/motoQuote.png";
import Stats from "../components/Aboutus/Stats";
import OurPatrons from "../components/Aboutus/OurPatrons/OurPatrons";
import Patronage from "../components/Aboutus/Patronage";
import Style from "../components/Aboutus/about.module.css";
// import Fade from "react-reveal/Fade";
import Lottie from "react-lottie";
import animationData from "../Assets/About/aboutusanimation.json";
import ourmottoanimation from "../Assets/About/ourmottoanimation.json";
import infinityloop from "../Assets/About/infinityloop.json";
import moto from "../Assets/About/moto.svg";
import circleAnimation from "../Assets/About/circleanimation.json";
import downarrow from "../Assets/About/downarrow.svg";
import API from "../Services/axios";
import data from "../data/data.json";
export default function About() {
  const [acc, setAcc] = useState();
  const [pat, setPat] = useState();

  useEffect(() => {
    async function getAccandPat() {
      // const data = (await API.get("/static/patandacc")).data;
      const data = data["patandacc"];
      // console.log(data);
      const pat = data.filter((data) => {
        if (data.category === "pat") {
          return data;
        }
        return null;
      });
      const acc = data.filter((data) => {
        if (data.category === "acc") {
          return data;
        }
        return null;
      });

      setAcc(acc);
      setPat(pat);
    }
    getAccandPat();
  }, []);

  const [showfull, setShowfull] = useState(false);
  const Lottiedefaultoptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const OurMottodefaultoptions = {
    loop: true,
    autoplay: true,
    animationData: ourmottoanimation,
  };
  const infinityloopoptions = {
    loop: true,
    autoplay: true,
    animationData: infinityloop,
  };
  const circleanimationoptions = {
    loop: true,
    autoplay: true,
    animationData: circleAnimation,
  };
  console.log(showfull);
  return (
    <>
      {/* <Head>
        <title>Aaruush '22 | About</title>
      </Head> */}

      <div className="bg-bgcolor  w-screen pt-20">
        <Fragment>
          <div className=" flex flex-col">
            {/* <Fade cascade> */}
            <div className="text-white flex flex-col md:flex-row overflow-y-hidden py-10 sm:py-5 px-7 sm:pl-10 md:pl-20">
              <div className="text-center md:text-left sm:my-auto md:w-[60vw] w-[90vw]">
                <div className="font-SFDistantGalaxy text-[26px] sm:text-[42px] leading-8 sm:leading-10">
                  One of the largest
                  <span className=" ml-5 mr-5 mb-2 before:block before:absolute before:-inset-1 before:translate-y-5 before:ml-3 before:h-[20px] before:bg-[#EF6522] relative inline-block">
                    <span className="relative text-white">
                      techno-management
                    </span>
                  </span>
                  fest in the country.
                </div>
                <div className="text-lg mt-10">
                  <p>
                    Aaruush is a growing family of like-minded, talented
                    individuals on their path to become pioneers in their
                    respective fields.
                  </p>
                </div>
                {/* <div className="font-SFDistantGalaxy text-[26px] sm:text-[28px] leading-8 sm:leading-10 pt-10">
                    <span className=" ml-5 mr-5 mb-2 before:block before:absolute before:-inset-1 before:translate-y-5 before:ml-3 before:h-[20px] before:bg-[#EF6522] relative inline-block">
                      <span className="relative text-white sm:text-[32px]">Aaruush</span>
                    </span>
                    is a growing family of like-minded, talented individuals on their path to become pioneers in their respective fields.
                  </div> */}
              </div>
              <div className="m-auto flex justify-end w-[90vw] md:w-[40vw]">
                <img className="w-max" src={Hero} alt="hero" />
              </div>
              {/* <div className="font-Montserrat text-[18px] block sm:hidden text-center">
                As a host to a plenitude of activities ranging from Architecture to Medical Sciences, Entrepreneurship to Robotics, and Game Development to Aeromodelling; the Events Team, comprises the 14 Domains along with Challenges & Championships, which are assisted by the 14 Aaruush Committees and Team Envision.
              </div> */}
            </div>
            {/* </Fade> */}
          </div>
        </Fragment>

        <Fragment>
          <div className="flex flex-col text-white py-10 px-7 sm:px-10 md:px-20">
            {/* <Fade bottom cascade> */}
            <div className="flex flex-col text-white">
              <h1 className="font-SFDistantGalaxy text-[28px] sm:text-[48px] text-center">
                We Made it happen!!
              </h1>
              <div className="overflow-y-hidden py-10 sm:py-5 px-7 sm:pl-10 md:pl-20 text-white font-Montserrat text-[24px] hidden sm:block">
                As a host to a plenitude of activities ranging from Architecture
                to Medical Sciences, Entrepreneurship to Robotics, and Game
                Development to Aeromodelling; the Events Team, comprises the 14
                Domains, which are assisted by the 14 Aaruush Committees and
                Team Envision.
              </div>
              <p className="font-Montserrat text-[18px] sm:text-[24px] text-center pt-5">
                In the pursuit of attaining the unattainable even during the
                times of uncertainty, Team Aaruush touched several lives and
                brought a change in the lives of thousands, With an astounding
                list of initiatives, events, and challenges, Team Aaruush has
                set a benchmark and have proved to be the torchbearers of the
                future.
              </p>
              {/* <p className="font-Montserrat text-[18px] sm:text-[24px] text-center pt-10">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mauris vel nunc velit. Proin efficitur magna erat, ut
                  efficitur nibh feugiat id. Vestibulum eleifend gravida
                  bibendum.
                </p> */}
            </div>
            <div className="flex flex-col md:flex-row text-white">
              <div className="pt-10 sm:pt-0 md:h-3/4 m-auto">
                <Lottie
                  options={Lottiedefaultoptions}
                  className="w-10/12 m-auto"
                />
              </div>
              <div className="pt-10 sm:pt-20 m-auto flex flex-row md:flex-col">
                <Stats edition={"Footfall last edition"} turnout={"76k+"} />
                <Stats edition={"Events and expos"} turnout={"60+"} />
                <Stats edition={"Guests in every edition"} turnout={"100+"} />
              </div>
            </div>
            {/* </Fade> */}
          </div>
        </Fragment>

        <Fragment>
          <div className="flex flex-col lg:flex-row  my-40">
            {/* <Fade bottom cascade> */}
            <div className="mb-10 sm:px-10 md:px-20 bg-[url(./Assets/About/circle_bg.png)] bg-contain bg-center bg-repeat-x">
              <h1 className="font-SFDistantGalaxy text-[32px] sm:text-[48px] text-center md:text-left text-white">
                About SRM
              </h1>
              <div className="px-7 md:px-0 md:w-1/2 pt-5 md:pt-0">
                <p className="text-justify font-Montserrat text-[16px] sm:text-[22px] text-white  row-start-1 row-end-1 col-start-1 col-end-1 z-[1]">
                  SRMIST has been a stalwart pillar in the field of higher
                  education since 1985. With over 50,000 students, including
                  international students from more than 64 countries,
                  state-of-the-art infrastructure and research facilities,
                  library, smart classrooms, SRMIST is one of the top ranked
                  private institutions in our nation. By receiving
                  accreditations from national agencies such as the Category 1
                  University recognition and 12B status by UGC, SRMIST has
                  obtained the prestigious 'A++' grade from the NAAC, and ranked
                  30th under the University category by NIRF, 2021. By providing
                  a wide range of undergraduate, postgraduate and doctoral
                  programs in Engineering, Management, Medicine and Health
                  Sciences, and Science and Humanities, SRM is marked as a
                  pioneer in the mission of guiding young minds on the trail of
                  innovation.
                </p>
              </div>
            </div>

            <div className=" md:absolute md:right-0 md:w-[600px] ">
              <img className="" src={srmmerge} alt="Pic Ring" />
            </div>
            {/* </Fade> */}
          </div>
        </Fragment>

        <Fragment>
          <div className="px-7 sm:px-10 md:px-20">
            {/* <Fade bottom cascade> */}
            <div
              className={
                "font-SFDistantGalaxy text-[32px] sm:text-[48px] text-center text-white bg-[url('./Assets/About/ourLegacy.svg')] bg-contain bg-no-repeat bg-center pt-10"
              }
            >
              Our Legacy
            </div>

            <div>
              <div className="flex flex-col md:flex-row py-0 ">
                <div className="w-70 md:w-[150vh] m-auto my-10 sm:py-0">
                  <Lottie options={circleanimationoptions} />
                </div>
                <div className="font-Montserrat text-[18px] sm:text-[24px] text-justify md:w-[500vh] text-center md:text-left text-white mt-20 pt-20 md:pl-10 md:my-auto flex flex-col ">
                  <div
                    className={
                      "md:h-[15rem] overflow-scroll " + Style.legacytext
                    }
                  >
                    The word 'Aaruush' translates to 'the first rays of the
                    sun'. Started by four visionary final year students of the
                    college, Aaruush was initially intended to be a technical
                    fest; consisting of 26 events and over 3000 participants,
                    but it exceeded the expectations of all. And so successful
                    was the idea, that the inauguration was presided over by the
                    former president of India,
                    <strong> Dr. A.P.J. Abdul Kalam</strong>
                    {!showfull ? " . . ." : null}
                    {showfull ? (
                      <p>
                        After its noteworthy beginning in 2007, there was no
                        looking back, it only kept getting better each year. The
                        following year saw Aaruush go national, with over 4500
                        participants from over 24 states. It was in 2009 that
                        Aaruush evolved from merely a technical fest, to a
                        Techno-Management extravaganza, as we saw the inclusion
                        of two new domains - Fundaz and Online. The reputation
                        of Aaruush kept rising, with fresh tie ups with
                        organizations like European Southern Observatory, IEEE,
                        European Space Agency, and even had DRDO unveil its
                        latest technology 'Arjun' tank at our premises. Reputed
                        NGOs like PETA, HOPE, CRY have associated with Aaruush,
                        reflecting its social responsibility. And it only got
                        better, Aaruush 2012 and 2013 hosted several stalwarts
                        of repute such as Dr. Richard Matthew Stallman, Mr.
                        Rajan Anandan, Dr. Tessy Thomas, Mr. Aakash Sinha, all
                        sharing their knowledge with the participants. Aaruush
                        2014 saw us welcome eminent personalities such as Dr.
                        Krishnaswamy Kasturirangan, Anuraag Batra, Dr. Eshwar
                        Natarajan and also welcomed Mr. Sam Pitroda, all the way
                        from America, with the help of state of the art video
                        conferencing technology.
                        <p>
                          Aaruush that year, witnessed an unprecedented
                          footfall, crossing 37000 and set another record, being
                          the platform where the new Arjun Mk II tank was first
                          unraveled. And for those who thought a
                          Techno-Management fest was only about education,
                          Aaruush proved that it was way more, by conducting
                          light and sound shows, Pro shows and the memorable
                          Aaruush Nites that attracted the masses by the
                          thousands. The various expos that took place,
                          including the coins expo, the newspaper expo, the
                          Indian Army Weapons Expo and the Auto Expo were prime
                          attractions.
                        </p>
                      </p>
                    ) : null}
                  </div>
                  {!showfull ? (
                    <div
                      className=" flex  mx-auto h-10 mt-10 cursor-pointer"
                      onClick={() => {
                        setShowfull(true);
                      }}
                    >
                      <div>Read More</div>
                      <img src={downarrow} alt="down" />
                    </div>
                  ) : (
                    <div
                      className="flex mx-auto h-10 mt-10 cursor-pointer"
                      onClick={() => {
                        setShowfull(false);
                      }}
                    >
                      <div>Show Less</div>
                      <img className="rotate-180" src={downarrow} alt="down" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* </Fade> */}
          </div>
        </Fragment>

        <Fragment>
          <div
            className={
              "flex flex-col md:flex-row-reverse p-10 mt-20 md:bg-[url('./Assets/About/ourthemebg.svg')] bg-contain bg-repeat"
            }
          >
            <div className="sm:w-full flex flex-col justify-start bg-[#606060] bg-opacity-40 rounded-3xl pb-10">
              <div className="h-[20rem]">
                <Lottie options={infinityloopoptions} />
              </div>
              <div className="font-SFDistantGalaxy text-[32px] md:text-[32px] text-center text-white ">
                Towards Infinity
              </div>
            </div>

            <div className=" flex flex-col justify-start mb-auto">
              <div className="font-SFDistantGalaxy text-[32px] sm:text-[48px] text-center md:text-left text-white md:my-auto">
                OUR THEME
              </div>
              <p className="font-Montserrat text-[18px] sm:text-[24px] text-center md:text-left text-white mt-10">
                Sending across the message for the need for innovation and
                technical breakthroughs to create a benchmark in the industry
                and make an actual difference. We believe in overcoming
                obstacles and exceeding expectations with tireless hard work and
                dedication.
              </p>
            </div>

            {/* <div className="w-60 md:w-full m-auto my-10 sm:py-0 md:block hidden">
              <img
                className="rounded-full"
                src={ourTheme}
                alt="Section3"
                width={500}
                height={500}
              />
            </div> */}
          </div>
        </Fragment>

        <Fragment>
          <div className="flex flex-col md:flex-row align-center justify-center mt-10 md:mt-20 px-7 sm:px-10 md:px-20">
            {/* <Fade bottom cascade> */}
            <div className="md:w-1/2">
              <Lottie options={OurMottodefaultoptions} width={`90%`} />
            </div>
            <div className="md:w-1/2 my-auto">
              <img src={moto} alt="moto" />
            </div>
            {/* </Fade> */}
          </div>
        </Fragment>

        <Fragment>
          <OurPatrons />
        </Fragment>

        <Fragment>
          {/* <Section7 /> */}
          <div className=" bg-no-repeat bg-contain bg-center pb-10">
            <div className="font-SFDistantGalaxy text-[32px] md:text-[48px] text-center text-white">
              Patronages & <br /> Accreditations
            </div>
            <div className="">
              <div className=" flex flex-wrap justify-center m-10 pt-10 text-white ">
                {pat &&
                  pat.map((image, idx) => {
                    return <Patronage key={idx} logo={image} />;
                  })}
              </div>
              <div className="my-10">
                <div className="w-20 h-2 bg-[#F17437] mx-auto" />
              </div>
              <div className="flex flex-wrap justify-center	m-10 pt-10">
                {acc &&
                  acc.map((image, idx) => {
                    return <Patronage key={idx} logo={image} />;
                  })}
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    </>
  );
}
