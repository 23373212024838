import React from "react";
import style from "./card.module.css";
import eventCover from "./eventCover.svg";
// import Image from "next/image";

const EventCard = ({ data }) => {
  return (
    <>
      <div
        className={`${style.eventCardBg} border-2 border-orange-500 h-[200px] w-[200px] md:h-[400px] md:w-[300px] rounded-xl flex flex-col gap-6 p-4 justify-center items-center`}
      >
        <div>
          <img alt="eventcover" src={data?.image} width={243} height={243} />
        </div>
        <div className="w-full flex">
          <p className="text-white text-center w-full font-center capitalize">
            {data.name == "srm-run"
              ? "SRM Run"
              : data?.name.split("-").join(" ")}
          </p>
          {/* <button className="bg-[#ef6522] p-1 text-white rounded-md w-[10vw]">
            View event
          </button> */}
        </div>
        {/* <hr className="bg-[#ef6522] border-none h-[2px] w-full" /> */}
      </div>
    </>
  );
};

export default EventCard;
