/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";


export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/clocktower.glb");
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      scale={0.55}
      position={[0, -2.5, 0]}
    >
      {/* Aaruush logo */}
      <group position={[0.01, 0.48, 1.74]}>
        <group position={[-0.05, 0, 0]} rotation={[1.57, 0, 0]} scale={1.5}>
          <mesh
            geometry={nodes.path38_1.geometry}
            material={materials["Material.011"]}
          />
          <mesh
            geometry={nodes.path38_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            geometry={nodes.path38_3.geometry}
            material={materials["Material.009"]}
          />
        </group>
      </group>
      <group position={[-1.74, 0.48, 0]}>
        <group position={[-0.05, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={1.5}>
          <mesh
            geometry={nodes.path38_1.geometry}
            material={materials["Material.011"]}
          />
          <mesh
            geometry={nodes.path38_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            geometry={nodes.path38_3.geometry}
            material={materials["Material.009"]}
          />
        </group>
      </group>
      <group position={[1.75, 0.48, 0]}>
        <group position={[-0.05, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.5}>
          <mesh
            geometry={nodes.path38_1.geometry}
            material={materials["Material.011"]}
          />
          <mesh
            geometry={nodes.path38_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            geometry={nodes.path38_3.geometry}
            material={materials["Material.009"]}
          />
        </group>
      </group>
      <group position={[0.08, 0.48, -1.68]}>
        <group position={[-0.05, 0, 0]} rotation={[Math.PI / 2, 0, Math.PI]} scale={1.5}>
          <mesh
            geometry={nodes.path38_1.geometry}
            material={materials["Material.011"]}
          />
          <mesh
            geometry={nodes.path38_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            geometry={nodes.path38_3.geometry}
            material={materials["Material.009"]}
          />
        </group>
      </group>
      {/* Date */}

      <mesh
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[-0.24, 0.18, 1.68]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[-1.75, 0.18, -0.24]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[0.24, 0.18, -1.68]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.Text.geometry}
        material={nodes.Text.material}
        position={[1.75, 0.18, 0.24]}
        rotation={[Math.PI/2, 0, -Math.PI / 2]}
        scale={0.11}
      />



      {/* piller strucutre */}
      <group scale={[1.58, 0.5, 1.58]}>
        <mesh geometry={nodes.Cube_1.geometry} material={materials.Material} />
        <mesh
          geometry={nodes.Cube_2.geometry}
          material={materials["Material.001"]}
        />
      </group>

      {/* Clock side area */}
      <group position={[0, 6.36, 0]} scale={[0.8, 1, 0.8]}>
        <mesh
          geometry={nodes.Cube001_1.geometry}
          material={materials.Material}
        />
        <mesh
          geometry={nodes.Cube001_2.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          geometry={nodes.Cube001_3.geometry}
          material={materials["Material.002"]}
        />
      </group>

      {/* top hemisphere */}
      <group position={[0, 9.39, 0]} scale={0.97}>
        <mesh
          geometry={nodes.Sphere_1.geometry}
          material={materials.Material}
        />
        <mesh
          geometry={nodes.Sphere_2.geometry}
          material={materials["Material.001"]}
        />
      </group>

      {/* top most part  */}

      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials.Material}
        position={[-0.01, 9.02, -0.01]}
        scale={[0.12, 0.02, 0.12]}
      />
      <mesh
        geometry={nodes.Cylinder001.geometry}
        material={materials.Material}
        position={[0.03, 9.11, 0.08]}
        scale={[0.02, 0.09, 0.02]}
      />
      <mesh
        geometry={nodes.Cylinder002.geometry}
        material={materials.Material}
        position={[0.08, 9.11, 0.03]}
        scale={[0.02, 0.09, 0.02]}
      />
      <mesh
        geometry={nodes.Cylinder003.geometry}
        material={materials.Material}
        position={[-0.01, 9.22, -0.01]}
        scale={[0.12, 0.02, 0.12]}
      />
      <mesh
        geometry={nodes.Cylinder004.geometry}
        material={materials.Material}
        position={[-0.01, 9.22, -0.01]}
        scale={[0.15, 0.01, 0.15]}
      />

      <mesh
        geometry={nodes.Sphere001.geometry}
        material={materials.Material}
        position={[-0.01, 9.51, -0.01]}
        scale={[0.23, 0.26, 0.23]}
      />
      <mesh
        geometry={nodes.Sphere002.geometry}
        material={materials.Material}
        position={[-0.01, 9.46, -0.01]}
        scale={[0.03, 0.07, 0.03]}
      />

      {/* laltain ki tarah jo hai total 12*/}
      <mesh
        geometry={nodes.Circle.geometry}
        material={materials.Material}
        position={[0.74, 5.38, 1.52]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.Circle001.geometry}
        material={materials.Material}
        position={[1.4, 5.38, 1.38]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.Circle002.geometry}
        material={materials.Material}
        position={[1.53, 5.38, 0.7]}
        scale={0.11}
      />
      <mesh
        geometry={nodes.Circle003.geometry}
        material={materials.Material}
        position={[0.71, 7.6, 0.7]}
        scale={0.11}
      />

      {/* clock area  */}
      {/* Clock hands */}
      <mesh
        geometry={nodes.Plane006.geometry}
        material={materials["Material.008"]}
        position={[-0.01, 6.52, 0.69]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane007.geometry}
        material={materials["Material.008"]}
        position={[-0.01, 6.52, 0.7]}
        rotation={[Math.PI / 2, 1.57, 0]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane001.geometry}
        material={materials["Material.008"]}
        position={[-0.01, 6.55, -0.69]}
        rotation={[-1.57, 0, 0.01]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane002.geometry}
        material={materials["Material.008"]}
        position={[-0.01, 6.55, -0.69]}
        rotation={[0.31, -1.56, 1.88]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane003.geometry}
        material={materials["Material.008"]}
        position={[0.69, 6.55, 0]}
        rotation={[-1.6, 0, -1.55]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane004.geometry}
        material={materials["Material.008"]}
        position={[0.69, 6.55, 0]}
        rotation={[3.11, -0.02, -1.57]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane005.geometry}
        material={materials["Material.008"]}
        position={[-0.69, 6.55, 0]}
        rotation={[-1.53, 0, Math.PI / 2]}
        scale={0.02}
      />
      <mesh
        geometry={nodes.Plane008.geometry}
        material={materials["Material.008"]}
        position={[-0.7, 6.55, 0]}
        rotation={[0.04, 0, Math.PI / 2]}
        scale={0.02}
      />

      <group
        position={[0, 6.53, 0.68]}
        rotation={[1.55, 0, 0]}
        scale={[0.014, 0, 0.014]}
      >
        {/* <mesh
          geometry={nodes.Cylinder005_1.geometry}
          material={materials["Material.003"]}
        /> */}
        <mesh
          geometry={nodes.Cylinder005_2.geometry}
          material={materials["Material.006"]}
        />
      </group>



      <group
        position={[0.68, 6.53, 0]}
        rotation={[Math.PI / 2, 0.02, -Math.PI / 2]}
        scale={[0.0135, 0, 0.0135]}
      >
        {/* <mesh
          geometry={nodes.Cylinder006_1.geometry}
          material={materials["Material.003"]}
        /> */}
        <mesh
          geometry={nodes.Cylinder006_2.geometry}
          material={materials["Material.006"]}
        />
      </group>

      <group
        position={[-0.01, 6.53, -0.68]}
        rotation={[1.59, 0, Math.PI]}
        scale={[0.014, 0, 0.014]}
      >
        {/* <mesh
          geometry={nodes.Cylinder007_1.geometry}
          material={materials["Material.003"]}
        /> */}

        <mesh
          geometry={nodes.Cylinder007_2.geometry}
          material={materials["Material.006"]}
        />
      </group>
      <group
        position={[-0.69, 6.53, 0]}
        rotation={[Math.PI / 2, -0.02, Math.PI / 2]}
        scale={[0.0135, 0, 0.0135]}
      >
        {/* <mesh
          geometry={nodes.Cylinder008_1.geometry}
          material={materials["Material.003"]}
        /> */}
        <mesh
          geometry={nodes.Cylinder008_2.geometry}
          material={materials["Material.006"]}
        />
      </group>

      {/* Bottom SRM logo both side circles */}
      <mesh
        geometry={nodes.Circle004.geometry}
        material={materials["Material.013"]}
        position={[1.3, 0.36, 1.54]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={0.15}
      />
      <mesh
        geometry={nodes.Circle005.geometry}
        material={materials["Material.013"]}
        position={[1.55, 0.36, 1.3]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.15}
      />

      <mesh
        geometry={nodes.Circle006.geometry}
        material={materials["Material.013"]}
        position={[-1.32, 0.36, 1.54]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={0.15}
      />
      <mesh
        geometry={nodes.Circle007.geometry}
        material={materials["Material.013"]}
        position={[1.55, 0.36, -1.33]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.15}
      />
      <mesh
        geometry={nodes.Circle008.geometry}
        material={materials["Material.013"]}
        position={[1.3, 0.36, -1.6]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={0.15}
      />
      <mesh
        geometry={nodes.Circle009.geometry}
        material={materials["Material.013"]}
        position={[-1.32, 0.36, -1.6]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={0.15}
      />
      <mesh
        geometry={nodes.Circle010.geometry}
        material={materials["Material.013"]}
        position={[-1.6, 0.36, 1.3]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.15}
      />
      <mesh
        geometry={nodes.Circle011.geometry}
        material={materials["Material.013"]}
        position={[-1.6, 0.36, -1.33]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.15}
      />

      {/* <mesh
        geometry={nodes.path50005.geometry}
        material={materials.main}
        position={[2.4, 0, -2.87]}
        scale={10.84}
      /> */}


    </group>
  );
}

useGLTF.preload("/clocktower.glb");
