import React from "react";
import Styles from "./Sponsor.module.css";
import sponsors_bg from "../../Assets/Sponsors/sponsorsbg.svg";
// import sponsors_bg from "../Assets/Images/sponsorsbg.svg"
import sponsors_img from "../../Assets/Sponsors/sponsors.svg";
import sponsors_fr from "../../Assets/Sponsors/sponsorfront.svg";
import Fade from "react-reveal/Fade";
import { HashLink } from "react-router-hash-link";
function Sponsors() {
  return (
    <div className="flex items-center md:h-[90vh] h-[50vh] justify-center p-8 flex-row-reverse flex-wrap md:flex-row md:basis-auto basis-1/3">
      <div className="flex flex-col font-bolder gap-8 md:mt-[15vh] mx-auto my-auto overflow-x-hidden">
        <img
          src={sponsors_bg}
          className={`md:h-[22vw] h-[30vh] md:mt-5 mt-5`}
          alt="sponsors"
        ></img>
        <div
          className={
            Styles.mainhead +
            ` ml-5 text-white text-5xl md:w-[40vw] w-[80vw] pb-4 translate-x-[10vw] translate-y-[-25vh] md:translate-x-[8vw] md:translate-y-[-18vw]`
          }
        >
          Sponsors
          <br /> Aaruush'22
        </div>
        <HashLink to="#contact">
          <button
            // onClick={() => {
            //   window.scrollTo(0, 100000);
            // }}
            className="absolute bottom-[55%] left-[35%] md:bottom-[20%] md:left-[23%] py-2 px-6 text-white rounded bg-[#ef6522] cursor-pointer hover:bg-[#ef652290] transition"
          >
            Sponsor Us
          </button>
        </HashLink>
      </div>
      <Fade top cascade>
        <div className="relative md:block hidden">
          <img
            src={sponsors_img}
            className={`mx-auto md:w-[38vw] mr-[9vw] mt-[-8vh] blur-sm`}
          />
          <img
            src={sponsors_fr}
            className={`mx-auto md:w-[35vw] mr-[9vw] mt-[-8vh] absolute top-[-2vh]`}
          />
        </div>
      </Fade>
    </div>
  );
}

export default Sponsors;
