import React from "react";
import Styles from "./Sponsor.module.css";
import sponsors_bg from "../../Assets/Sponsors/sponsorsbg.svg";
import Card from "./cc";
export default function Contact() {
  return (
    <div className=" flex flex-col" id="contact">
      <div className="flex items-center md:h-[90vh] h-[50vh] justify-center p-8 flex-row-reverse flex-wrap md:flex-row">
        <div className="flex flex-col font-bolder gap-8 md:mt-[15vh] mx-auto my-auto overflow-x-hidden">
          <img
            src={sponsors_bg}
            className={`md:h-[22vw] h-[30vh] md:mt-5 mt-5`}
            alt="sponsor"
          ></img>
          <div
            className={
              Styles.mainhead +
              ` ml-5 text-white text-5xl md:w-[40vw] w-[80vw] pb-4 translate-x-[10vw] translate-y-[-25vh] md:translate-x-[8vw] md:translate-y-[-18vw]`
            }
          >
            Contact
            <br /> Us Here
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-around px-4 mb-8">
        <Card
          name="Arnav Sharma"
          email="sponsorship@aaruush.net"
          phone="+918871414237"
          designation={"Organizer"}
        />
        <Card
          name="Abhilakshit Kathait"
          email="sponsorship@aaruush.net"
          phone="+917221038238"
          designation={"Organizer"}
        />
        <Card
          name="Rachana Komanduri"
          email="sponsorship@aaruush.net"
          phone="+917619454739"
          designation={"Organizer"}
        />
      </div>
    </div>
  );
}
