// import Image from "next/image";
import styles from "../Assets/Timeline/Timeline.module.css";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useState } from "react";
// import carImg from "../Assets/Timeline/CAR.png";
// import data from "../Assets/Timeline/timelineEditions.json";
import { IoFootstepsOutline, IoFootsteps } from "react-icons/io5";
import { useEffect } from "react";
import API from "../Services/axios";
import loadingaaruush from "../Assets/aaruushloading.json";
import Lottie from "react-lottie";
import data from "../data/data.json";

const Timeline = () => {
  const [data, setData] = useState();
  const [length, setLength] = useState(0);
  const Lottiedefaultoptions = {
    loop: true,
    autoplay: true,
    animationData: loadingaaruush,
    rendererSettings: {
      preserveAspectRatio: "xMindYMid slice",
    },
  };
  useEffect(() => {
    async function getTimeline() {
      // const timeline = await API.get("/static/timeline");
      const timeline = data["timeline"];
      // console.log(timeline.data);
      //   console.log(timeline);
      setLength(timeline.length);
      //   console.log(timeline);
      const sorted = timeline.sort(function (a, b) {
        // console.log(a.designation - b.designation);
        // return a.designation - b.designation;
        // return a.name - b.name;
        return a.timestamp - b.timestamp;
      });
      setData(sorted);
      //   setData(timeline.data);
    }
    getTimeline();
  }, []);
  //   console.log("Hehe", data);
  const [index, setIndex] = useState(0);
  const [x, setX] = useState(0);
  const goLeft = () => {
    setX(x + 100);
    if (index === 0) {
      setIndex(length - 1);
      setX(-100 * (length - 1));
    } else {
      setIndex(index - 1);
    }
    // console.log("l", index, x);
  };
  const goRight = () => {
    if (x === (length - 1) * -100) {
      setX(0);
    } else {
      setX(x - 100);
    }
    if (index === length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
    // console.log("r", index, x);
  };

  return (
    <div className="bg-bgcolor min-h-screen h-screen pt-[7vh]">
      {data ? (
        <>
          <div className={`flex flex-row`}>
            {data?.map((data, index) => {
              console.log(data.length);
              return (
                <>
                  <div
                    className={`min-w-[93%] transition-all duration-1000 ${styles.xsm}`}
                    style={{ transform: `translateX(${x}%)` }}
                    key={index}
                  >
                    <div
                      className={`${styles.lastDiv} flex  p-10 gap-[18vw] h-[90vh] overflow-x-hidden`}
                    >
                      <div className={`${styles.edition} `}>
                        <p className={`text-[35vh] text-white`}>{data.name}</p>
                        <p
                          className={`text-[19vh] text-orange-600 -mt-28 pl-5`}
                        >
                          EDITION
                        </p>
                      </div>

                      <div
                        className={`${styles.Content} my-auto text-[3vh] text-white`}
                      >
                        {data.hometext}
                      </div>
                    </div>

                    {/* <div className="absolute left-[42vh] top-[23vh] text-center z-20 ml-24 ">
                                    <img
                                        src={carImg}
                                        width={796}
                                        height={530}
                                        className={`${styles.carImg} `}
                                        alt="car"
                                    />
                                </div> */}
                  </div>

                  <div
                    className={`min-w-[100%] transition-all duration-1000 2xl:hidden`}
                    style={{ transform: `translateX(${x}%)` }}
                    key={index}
                  >
                    <div className={`flex flex-col justify-center  `}>
                      <div className={`${styles.edition} `}>
                        <p
                          className={`text-[14vh] text-white text-center lg:text-[30vh]`}
                        >
                          {data.name}
                        </p>
                        <p
                          className={` text-[8vh] text-orange-600 text-center -mt-12 pl-2 sm:-mt-16 lg:text-[17vh] xl:-mt-24`}
                        >
                          EDITION
                        </p>
                      </div>

                      {/* <div className="ml-10 relative bottom-[6vh] sm:bottom-[8vh] lg:ml-32 lg:bottom-[16vh] xl:ml-64">
                                        <img alt=" car" src={carImg} width={796} height={530} />
                                    </div> */}

                      <div className="flex justify-between relative bottom-[-40vh] px-2 sm:bottom-[46vh] lg:bottom-[90vh] lg:px-4 xl:bottom-[70vh] ">
                        <div
                          className={`${styles.circle} flex items-center w-[6vh] h-[6vh] sm:w-[8vh] sm:h-[8vh] lg:w-[16vh] lg:h-[16vh] `}
                        >
                          <div>
                            <BsArrowLeft
                              className={`text-[8vh] sm:text-[10vh] lg:text-[24vh] text-white 2xl:cursor-pointer relative left-[1.8vh] `}
                              onClick={x > 0 ? setX(-(length * 100)) : goLeft}
                            />
                          </div>
                        </div>
                        <div
                          className={`${styles.circle} flex items-center w-[6vh] h-[6vh] sm:w-[8vh] sm:h-[8vh] lg:w-[16vh] lg:h-[16vh]  `}
                        >
                          <div>
                            <BsArrowRight
                              className={`text-[8vh]  sm:text-[10vh] lg:text-[24vh] text-white 2xl:cursor-pointer relative  right-[4vh] lg:right-[10vh] `}
                              onClick={x < -(length * 100) ? setX(0) : goRight}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${styles.Content} text-white -mt-12 px-8 sm:-mt-48 xl:-mt-64`}
                      >
                        <p className="text-center text-lg sm:text-2xl xl:text-3xl ">
                          {data.hometext}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="flex flex-col py-12 sm:pt-20 2xl:my-0 2xl:justify-between 2xl:px-20 2xl:flex-row 2xl:-mt-52 2xl:mb-48 2xl:z-50">
            <div
              className={` flex flex-col gap-2 items-center justify-center sm:gap-4 2xl:max-w-md 2xl:flex-row 2xl:gap-6  2xl:z-50`}
            >
              <button className="rounded-full  bg-white w-[10vh] h-[10vh] hover:bg-aaruush flex justify-center peer items-center pb-4 group transition xl:translate-y-0 -translate-y-10">
                <p className="text-4xl mt-4 text-black group-hover:hidden transition">
                  <IoFootstepsOutline />
                </p>
                <p className="text-4xl  mt-4 text-black group-hover:block hidden transition">
                  <IoFootsteps />
                </p>
              </button>
              <div className="text-xl text-white peer-hover:text-aaruush sm:text-2xl 2xl:hidden font-SpaceGrotesk -translate-y-9 pt-2 transition">
                <p className="pb-[10vh] transition ">
                  {data ? data[index].footfall : 0}
                </p>
              </div>
              <div
                className={`${styles.xsm} text-3xl text-white font-SpaceGrotesk peer-hover:text-aaruush`}
              >
                <p className="transition"> {data ? data[index].footfall : 0}</p>
              </div>
            </div>

            <div
              className={`${styles.xsm} flex justify-between  px-0 gap-12 z-50 `}
            >
              <div
                className={`${styles.circle} flex items-center  w-[10vh]  h-[10vh] cursor-pointer group `}
              >
                <div>
                  <BsArrowLeft
                    className={` text-7xl text-white cursor-pointer relative left-8 group-hover:animate-left`}
                    onClick={x > 0 ? setX(-(length * 100)) : goLeft}
                  />
                </div>
              </div>
              <div
                className={`${styles.circle} flex items-center w-[10vh] h-[10vh] cursor-pointer group `}
              >
                <div>
                  <BsArrowRight
                    className={`text-7xl  text-white cursor-pointer relative right-8 group-hover:animate-right`}
                    onClick={x < -(length * 100) ? setX(0) : goRight}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="h-screen w-screen flex relative justify-center items-center bg-bgcolor">
          <Lottie options={Lottiedefaultoptions} />
        </div>
      )}
    </div>
  );
};

export default Timeline;
