import React from "react";
import style from "./card.module.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const GwrCard = ({ year, name, desc, bg }) => {
  return (
    <>
      <div
        className={`${style.gwrCardBg} ${bg === "ecorun" ? style.ecorun : style.gwr
          } border-2 border-[#ef6522] h-full sm:mx-5 mb-5 md:mb-0 flex flex-col gap-3 p-8 rounded-3xl backdrop-blur-sm ] `}
      >
        <div>
          <p className="text-white font-Montserrat text-xl">{year}</p>
          <p className="text-white text-[4vh]">{name}</p>
        </div>
        <div>
          <p className="text-white text-xl font-Montserrat">{desc}</p>
        </div>
        <div className="flex items-center justify-between relative top-6">
          <p className="text-white font-Montserrat text-sm md:text-base ">
            View other Aaruush Initiatives
          </p>
          <Link to="/initiatives">
            <div
              className={`${style.circle}  w-[8vh] h-[8vh] cursor-pointer group flex items-center  `}
            >
              <BsArrowRight
                className={`text-[8vh] text-[#ef6522] cursor-pointer relative  right-[2vh]
                  group-hover:animate-right`}
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default GwrCard;
