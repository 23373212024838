import React from "react";
import SingleSponsor from "./SingleSponsor";

function Sponsors({ sponsors }) {
  return (
    <div className="text-white mt-10">
      <div className="md:w-100 md:ml-1 md:mt-[5vh] flex flex-column flex-wrap justify-center">
        {sponsors.map((sponsor, idx) => {
          return <SingleSponsor props={sponsor} key={idx} />;
        })}
      </div>
    </div>
  );
}

export default Sponsors;
