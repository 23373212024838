import React from "react";
import { MdEmail } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import Styles from "./profile.module.css";
import { Tooltip } from "@mui/material";
// import {} from react-icons
// import photo from "../../Assets/Images/samplefiller.svg";

// import { useState } from "react/cjs/react.development";
// import Fade from "react-reveal/Fade";
// import { FaArrowCircleUp } from "react-icons/fa";

// function mouseEnter(abc) {
//   const mediaQuery = window.matchMedia("(min-width: 1025px)");
//   const mediaQuery2 = window.matchMedia("(max-width: 1025px)");
//   if (mediaQuery.matches) {
//     var searchEles = document.querySelectorAll(".profileClass");
//     var descEles = document.querySelectorAll(".descriptionClass");
//     var arrow = document.querySelectorAll(".arrowClass");

//     for (var i = 0; i < searchEles.length; i++) {
//       if (searchEles[i].id != abc) {
//         searchEles[i].classList.add("blur-sm");
//         searchEles[i].classList.add("-z-50");
//       } else {
//         if (i % 4 == 0 || i % 4 == 1) {
//           descEles[i].classList =
//             "translate-x-[18vw] translate-y-[-25vh] absolute w-[25vw] z-30 visible rounded-md bg-[#4f4f4f] text-gray-50 descriptionClass p-5";
//           // searchEles[++i].classList.add("hidden");
//           // // // console.log(searchEles[++i]);
//         } else {
//           // searchEles[i - 1].classList.add("hidden");
//           descEles[i].classList =
//             "translate-x-[-30vw] translate-y-[-25vh] absolute w-[25vw] z-30 visible rounded-md bg-[#4f4f4f] text-gray-50 descriptionClass p-5";
//           // // // console.log(arrow[i],i);
//           arrow[i].classList =
//             "absolute w-16 overflow-hidden inline-block transform rotate-90 top-[20%] right-0 translate-x-[65%] arrowClass";
//         }
//       }
//     }
//   } else if (mediaQuery2.matches) {
//     var searchEles = document.querySelectorAll(".profileClass");
//     var nameEles = document.querySelectorAll(".nameprofile");
//     var descEles = document.querySelectorAll(".descriptionmobile");
//     var arrow = document.querySelectorAll(".arrowmobileClass");

//     for (var i = 0; i < searchEles.length; i++) {
//       if (searchEles[i].id != abc) {
//         searchEles[i].classList.add("blur-sm");
//         searchEles[i].classList.add("-z-50");
//       } else {
//         nameEles[i].classList.add("hidden");
//         if (i % 2 == 0) {
//           descEles[i].classList.add(Styles.mobiledesceven);
//           arrow[i].classList.add("left-5");
//         } else {
//           descEles[i].classList.add(Styles.mobiledescodd);
//           arrow[i].classList.add("right-5");
//         }
//       }
//     }
//   }
// }

// function mouseLeave(abc) {
//   // // // console.log(`Left ${abc}`);
//   var searchEles = document.querySelectorAll(".profileClass");
//   var descEles = document.querySelectorAll(".descriptionClass");
//   var mobdescEles = document.querySelectorAll(".descriptionmobile");
//   var profile = document.querySelectorAll(".nameprofile");
//   // var arrow = document.querySelectorAll('.arrowClass');
//   // // // console.log(searchEles);
//   for (var i = 0; i < searchEles.length; i++) {
//     searchEles[i].classList.remove("blur-sm");
//     searchEles[i].classList.remove("-z-50");
//     searchEles[i].classList.remove("hidden");
//     descEles[i].classList =
//       "absolute w-[25vw] z-30 hidden rounded-md bg-[#4f4f4f] text-gray-50 descriptionClass p-5";
//     mobdescEles[i].classList.remove(Styles.mobiledescodd);
//     mobdescEles[i].classList.remove(Styles.mobiledesceven);
//     profile[i].classList.remove("hidden");
//   }
// }

export default function profile({ members }) {
  // // // console.log(members);
  // // // console.log(members.photo);
  // const [description, setdescription] = useState(false);
  // const imgsrc = require("../../Assets/Images/" + members.photo);
  // // // console.log(imgsrc)
  var name = members.name.split("-").join(" ");
  return (
    <React.Fragment>
      {/* The Profile Component */}
      <div
        className={
          "relative justify-evenly flex mt-7 z-20 profileClass transition duration-300 group " +
          Styles.mobileprofile
        }
        id={members.id}
        // onMouseEnter={(e) => {
        //   mouseEnter(members.id);
        // }}
        // onMouseLeave={(e) => {
        //   mouseLeave(members.id);
        // }}
      >
        <div className="align-top inline-block text-center relative hover:scale-110 ease-in transition">
          <img
            className={`rounded-full h-[15vh] lg:h-[25vh] mx-auto border-orange-400 border-solid border-4 group-hover:shadow-xl shadow-md shadow-[#ef6522] transition`}
            src={members.image}
            alt="profile"
          />
          {/* The Description Box for Laptop (min-width:1025px) */}
          {/* <div
            className={
              `absolute w-[25vw] z-30 hidden rounded-md bg-[#4f4f4f] text-gray-50 descriptionClass p-5 ` +
              Styles.descriptionClass
            }
          >
            <div className="absolute w-16 overflow-hidden inline-block transform -rotate-90 top-[20%] left-0 translate-x-[-65%] arrowClass">
              <div className=" h-11 w-11 bg-[#4f4f4f] rotate-45 transform origin-bottom-left"></div>
            </div>
            <div className={Styles.descname}>
              <p className="mb-2 font-bold">{members.designation}</p>
              <p className="mb-2 font-semibold capitalize">{name}</p>
              <p className="mb-2 ">{members.email}</p>
            </div>

            <div className={Styles.descdesc}>{members.about}</div>
          </div> */}
          {/* The Description box for mobile (max-width:1025px) */}
          {/* <div
            className={
              "w-[70vw] mt-10 z-30 rounded-md text-gray-50 p-5 absolute descriptionmobile " +
              Styles.descriptionmobile
            }
          >
            <div
              className={
                "absolute w-20 overflow-hidden inline-block transform top-[-10%] arrowmobileClass even:left-5 " +
                Styles.mobarrow
              }
            >
              <div className=" h-11 w-11 bg-[#4f4f4f] rotate-45 transform origin-bottom-left"></div>
            </div>
            <div className={Styles.descname}>
              <p className="mb-2 font-bold">{members.designation}</p>
              <p className="mb-2 font-semibold capitalize">{name}</p>
              <p className="mb-2 ">{members.email}</p>
            </div>

            <div className={Styles.descdesc}>{members.about}</div>
          </div> */}

          <div className="mt-2 nameprofile">
            <p
              className={
                Styles.personname +
                " capitalize bold group-hover:text-[#ef6522] transition"
              }
            >
              {name}
            </p>
            <p className="text-white">{members.designation}</p>
            <p className="flex flex-row justify-center mt-3 gap-x-2">
              <Tooltip title={members.email} arrow>
                <a
                  className="text-white transition hover:text-[#ef6522] text-lg"
                  href={`mailto:${members.email}`}
                >
                  <MdEmail />
                </a>
              </Tooltip>
              {members.linkedin ? (
                <Tooltip title="Linkedin" arrow>
                  <a
                    className="text-white transition hover:text-[#ef6522] text-lg"
                    href={members.linkedin}
                    target="_blank"
                  >
                    <BsLinkedin />
                  </a>
                </Tooltip>
              ) : null}
              {members.email2 ? (
                <Tooltip title={members.email2} arrow>
                  <a
                    className="text-white transition hover:text-[#ef6522] text-lg"
                    href={`mailto:${members.email2}`}
                  >
                    <MdEmail />
                  </a>
                </Tooltip>
              ) : null}
            </p>
            <p className="text-white transition group-hover:text-[#ef6522]"></p>
          </div>
        </div>
      </div>
      {/* <div className="hidden  text-gray-50 p-5 ml-[-5vw] translate-x-[-55vw] absolute w-[85vw] z-30 visible rounded-md bg-gray-800 text-gray-50p-5 mt-10"></div> */}
    </React.Fragment>
  );
}
