import React from 'react';
import Styles from './Sponsor.module.css'
import sponsors_bg from "../../Assets/Sponsors/sponsorsbg.svg"


function Sponsors() {
    return (
    <div className='flex items-center md:h-[90vh] h-[50vh] justify-center p-8 flex-row-reverse flex-wrap md:flex-row'>
        <div className='flex flex-col font-bolder gap-8 md:mt-[15vh] mx-auto my-auto overflow-x-hidden' >
            <img src={sponsors_bg} className={`md:h-[22vw] h-[30vh] md:mt-5 mt-5`} alt="sponsor"></img>
            <div className={Styles.mainhead+` ml-5 text-white text-5xl md:w-[40vw] w-[80vw] pb-4 translate-x-[10vw] translate-y-[-25vh] md:translate-x-[8vw] md:translate-y-[-18vw]`}>
            Previous<br /> Sponsors
            </div>
        </div >
       
    </div >);
}

export default Sponsors;